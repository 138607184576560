import React, { useState } from 'react';
import axiosInstance from '../Admin/axiosInstance';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Checkbox } from '@mui/material';

const DispatchParcel = () => {
  const [parcels, setParcels] = useState([]); // Holds parcels to be displayed
  const [selectedParcels, setSelectedParcels] = useState([]); // Holds selected parcels for dispatch
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [source, setSource] = useState('');
  const [destination, setDestination] = useState('');
  const [pickup, setPickup] = useState('');
  const [drop, setDrop] = useState('');
  const [loadingSheetNumber, setLoadingSheetNumber] = useState('');
  const [selectedDirection, setSelectedDirection] = useState(1); // Holds the selected direction

  // Fetch parcels through API without any parameters
  const fetchParcels = async () => {
    try {
      const response = await axiosInstance.get(`/parcel/dispatch/${selectedDirection}`);
      setParcels(response.data);
    } catch (error) {
      console.error('Error fetching parcels', error);
    }
  };

  // Handle select/unselect of parcel items
  const handleSelectParcel = (parcel) => {
    if (selectedParcels.includes(parcel)) {
      setSelectedParcels(selectedParcels.filter((p) => p !== parcel));
    } else {
      setSelectedParcels([...selectedParcels, parcel]);
    }
  };

  // Move selected parcels from all parcels to dispatched parcels
  const moveToDispatched = () => {
    const newSelectedParcels = parcels.filter((parcel) => selectedParcels.includes(parcel));
    setParcels(parcels.filter((parcel) => !selectedParcels.includes(parcel))); // Remove from all parcels
    setSelectedParcels([]); // Clear selected parcels
  };

  // Move parcels back from dispatched to all parcels
  const moveToAllParcels = () => {
    const newDispatchedParcels = [...parcels, ...selectedParcels]; // Add them back to all parcels
    setParcels(newDispatchedParcels);
    setSelectedParcels([]);
  };

  // Dispatch selected parcels through API
  const dispatchParcels = async () => {
    try {
      // Dispatch each selected parcel individually
      for (const parcel of selectedParcels) {
        await axiosInstance.patch(`/parcel/${parcel.id}`, {
          status: 'DISPATCHED',
          loadingSheetNumber,
        });
      }
      alert('Parcels dispatched successfully');
      setSelectedParcels([]); // Clear selected parcels after dispatch
    } catch (error) {
      console.error('Error dispatching parcels', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Dispatch Parcel</h2>

      {/* Filters Section */}
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ marginRight: '10px' }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ marginRight: '10px' }}
        />
        <TextField
          label="Pickup"
          value={pickup}
          onChange={(e) => setPickup(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <TextField
          label="Drop"
          value={drop}
          onChange={(e) => setDrop(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <FormControl style={{ marginRight: '10px' }}>
          <InputLabel>Route</InputLabel>
          <Select value={selectedDirection} onChange={(e) => setSelectedDirection(e.target.value)}>
            <MenuItem value={1}>Nairobi-Wajir</MenuItem>
            <MenuItem value={2}>Wajir-Nairobi</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={fetchParcels}>
          Search
        </Button>
      </div>

      {/* Parcel Tables Section */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Left Table: All Searched Parcels */}
        <div style={{ flex: 1, marginRight: '20px' }}>
          <h3>All Searched Parcel(s)</h3>
          <table border="1" width="100%">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Select</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Date</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Sender Name</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Receiver Name</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Parcel No.</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Origin - Destination</th>
              </tr>
            </thead>
            <tbody>
              {parcels.map((parcel, index) => (
                <tr key={index}>
                  <td>
                    <Checkbox
                      checked={selectedParcels.includes(parcel)}
                      onChange={() => handleSelectParcel(parcel)}
                    />
                  </td>
                  <td>{parcel.bookDate}</td>
                  <td>{parcel.senderName}</td>
                  <td>{parcel.receiverName}</td>
                  <td>{parcel.trackingCode}</td>
                  <td>{parcel.source} - {parcel.destination}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Arrow Buttons for Moving Parcels */}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Button variant="contained" onClick={moveToDispatched}>&rarr;</Button>
          <Button variant="contained" onClick={moveToAllParcels}>&larr;</Button>
        </div>

        {/* Right Table: Selected Parcels for Dispatch */}
        <div style={{ flex: 1 }}>
          <h3>Item(s) Dispatched</h3>
          <table border="1" width="100%">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Date</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Sender Name</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Receiver Name</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Parcel No.</th>
                <th style={{ backgroundColor: '#1976d2', color: '#fff' }}>Source - Destination</th>
              </tr>
            </thead>
            <tbody>
              {selectedParcels.map((parcel, index) => (
                <tr key={index}>
                  <td>{parcel.bookDate}</td>
                  <td>{parcel.senderName}</td>
                  <td>{parcel.receiverName}</td>
                  <td>{parcel.trackingCode}</td>
                  <td>{parcel.source} - {parcel.destination}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Dispatch Section */}
      <div style={{ marginTop: '20px' }}>
        {/* <TextField
          label="Loading Sheet Number"
          value={loadingSheetNumber}
          onChange={(e) => setLoadingSheetNumber(e.target.value)}
          style={{ marginRight: '10px' }}
        /> */}
        <Button variant="contained" color="secondary" onClick={dispatchParcels}>
          Dispatch
        </Button>
      </div>
    </div>
  );
};

export default DispatchParcel;
