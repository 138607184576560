import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import axiosInstance from './axiosInstance';
import { useReactToPrint } from 'react-to-print';

const AdminParcels = () => {
    const [parcels, setParcels] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredParcels, setFilteredParcels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('INPROGRESS');
    const [collectedParcels, setCollectedParcels] = useState([]);
    const [showCollectedParcels, setShowCollectedParcels] = useState(false);
    const [collectedError, setCollectedError] = useState(null);
    const tableRef = useRef(); // For printing

    useEffect(() => {
        const fetchParcels = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosInstance.get('/parcel/viewAll');
                setParcels(response.data);
                setFilteredParcels(response.data);
            } catch (error) {
                console.error('Error fetching parcels:', error);
                setError('Failed to load parcels. Please try again later.');
            }
            setLoading(false);
        };
        fetchParcels();
    }, []);

    const handleStatusChange = async (event) => {
        const selectedStatus = event.target.value;
        setStatus(selectedStatus);
        setLoading(true);
        setError(null);

        try {
            const response = await axiosInstance.get(`/parcel/report/${selectedStatus}`);
            setFilteredParcels(response.data);
        } catch (error) {
            console.error(`Error fetching parcels with status ${selectedStatus}:`, error);
            setError(`Failed to load parcels with status "${selectedStatus}". Please try again later.`);
            setFilteredParcels([]);
        }
        setLoading(false);
    };

    const handleSearch = async () => {
        if (searchQuery) {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosInstance.get(`/parcel/status/${searchQuery}`);
                setFilteredParcels([response.data]);
            } catch (error) {
                console.error('Error searching for parcel:', error);
                setError('Parcel not found or an error occurred. Please try again later.');
                setFilteredParcels([]);
            }
            setLoading(false);
        } else {
            setFilteredParcels(parcels);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    const handleViewCollectedParcels = async () => {
        setLoading(true);
        setCollectedError(null);
        setError(null);
        try {
            const response = await axiosInstance.get('/parcelreceiver/all');
            setCollectedParcels(response.data);
            setShowCollectedParcels(true);
        } catch (error) {
            console.error('Error fetching collected parcels:', error);
            setCollectedError('Failed to load collected parcels. Please try again later.');
            setCollectedParcels([]);
        }
        setLoading(false);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                Parcel Management
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={handleStatusChange}
                        label="Status"
                    >
                        <MenuItem value="INPROGRESS">Received</MenuItem>
                        <MenuItem value="DISPATCHED">Dispatched</MenuItem>
                        <MenuItem value="DELIVERED">Delivered</MenuItem>
                        <MenuItem value="COLLECTED">Collected</MenuItem>
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Search by Tracking Number"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ marginRight: 1 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        size="small"
                        disabled={loading}
                    >
                        Search
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handlePrint}
                        size="small"
                        sx={{ marginLeft: 1 }}
                    >
                        Print
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleViewCollectedParcels}
                        size="small"
                        sx={{ marginLeft: 1 }}
                        disabled={loading}
                    >
                        View Collected Parcels
                    </Button>
                </Box>
            </Box>

            {loading && <Typography variant="body1">Loading...</Typography>}
            {error && <Typography variant="body1" color="error">{error}</Typography>}
            {collectedError && <Typography variant="body1" color="error">{collectedError}</Typography>}

            <TableContainer component={Paper} ref={tableRef}>
                {!showCollectedParcels ? (
                    <Table>
                        <TableHead sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#fff' }}>Tracking Number</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Sender Name</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Recipient Name</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Source</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Destination</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Price</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Phone (Sender)</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Phone (Recipient)</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredParcels.length > 0 ? (
                                filteredParcels.map(parcel => (
                                    <TableRow key={parcel.trackingCode}>
                                        <TableCell>{parcel.trackingCode}</TableCell>
                                        <TableCell>{parcel.senderName}</TableCell>
                                        <TableCell>{parcel.receiverName}</TableCell>
                                        <TableCell>{parcel.source}</TableCell>
                                        <TableCell>{parcel.destination}</TableCell>
                                        <TableCell>Ksh{parcel.price}</TableCell>
                                        <TableCell>{parcel.senderPhone}</TableCell>
                                        <TableCell>{parcel.receiverPhone}</TableCell>
                                        <TableCell>{parcel.status === 'INPROGRESS' ? 'RECEIVED' : parcel.status}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        {searchQuery ? 'No parcels found' : 'No parcels available'}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                ) : (
                    <Table>
                        <TableHead sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#fff' }}>Tracking Number</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Sender Name</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Receiver Name</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Source</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Destination</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Collected By (Name)</TableCell>
                                <TableCell sx={{ color: '#fff' }}>ID Number</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Phone Number</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Collection Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {collectedParcels.length > 0 ? (
                                collectedParcels.map(({ parcel, firstName, lastName, phoneNumber, idNumber, date }) => (
                                    <TableRow key={parcel.trackingCode}>
                                        <TableCell>{parcel.trackingCode}</TableCell>
                                        <TableCell>{parcel.senderName}</TableCell>
                                        <TableCell>{parcel.receiverName}</TableCell>
                                        <TableCell>{parcel.source}</TableCell>
                                        <TableCell>{parcel.destination}</TableCell>
                                        <TableCell>{`${firstName} ${lastName || ''}`}</TableCell>
                                        <TableCell>{idNumber}</TableCell>
                                        <TableCell>{phoneNumber}</TableCell>
                                        <TableCell>{date}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No collected parcels available.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Box>
    );
};

export default AdminParcels;
