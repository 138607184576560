import React, { useEffect, useState } from 'react';
import { Typography, Container, Card, CardContent, CardMedia, Button, Box, Grid, Dialog, DialogContent } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SeatingLayout from './SeatingLayout';
import bannerImage from '../images/Home_page_banner2.png';
import axios from 'axios';

const BusList = () => {
  const [buses, setBuses] = useState([]);
  const [selectedBus, setSelectedBus] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchBuses = async () => {
      const storedBuses = localStorage.getItem('buses');
      if (storedBuses) {
        const busesData = JSON.parse(storedBuses);
        // Fetch booked seats for each bus
        const updatedBuses = await Promise.all(busesData.map(async (bus) => {
          try {
            const response = await axios.get(`http://localhost:8088/reservation/bookedseats/${bus.scheduleId}`);
            const bookedSeats = response.data.length; // Assuming response.data contains a list of booked seats
            return {
              ...bus,
              availableSeats: 49 - bookedSeats
            };
          } catch (error) {
            console.error('Error fetching booked seats', error);
            return {
              ...bus,
              availableSeats: 49 // Fallback to 49 if there's an error
            };
          }
        }));
        setBuses(updatedBuses);
      }
    };

    fetchBuses();
  }, []);

  const handleSelectSeat = (bus) => {
    setSelectedBus(bus);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container style={{ marginTop: '2rem', justifyContent: 'center' }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            Available Buses
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 2,
            overflowX: 'auto',
            justifyContent: 'center',
          }}
        >
          {buses.map((bus) => (
            <Card key={bus.id} sx={{ display: 'flex', minWidth: 600, maxWidth: 800, flexShrink: 0 }}>
              <CardMedia
                component="img"
                alt={`Bus ${bus.busRegistration}`}
                sx={{ width: 150, height: 100, objectFit: 'cover' }}
                image={bannerImage} // Assuming `bus.image` contains the URL of the bus image
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center">
                    <Box>
                      <Typography variant="h6">{bus.busRegistration}</Typography>
                      <Typography variant="body2" color="textSecondary">Ksh.{bus.price}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>{bus.source}</Typography>
                      <ArrowForwardIcon sx={{ mx: 1 }} />
                      <Typography variant="body2" color="textSecondary">{bus.destination}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center" justifyContent="center">
                    <Box>
                      <Typography variant="body2" color="textSecondary">{bus.scheduleDate}</Typography>
                      <Typography variant="body2" color="textSecondary">{bus.scheduleTime}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center" justifyContent="flex-end">
                    <Box>
                      <Typography variant="body2" color="textSecondary">Seats Available: {bus.availableSeats}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center" justifyContent="flex-end">
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: 'auto' }}
                        onClick={() => handleSelectSeat(bus)}
                      >
                        Select Seat
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Box>
        {/* Dialog for Seating Layout */}
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
          <DialogContent>
            {selectedBus && <SeatingLayout bus={selectedBus} />}
          </DialogContent>
        </Dialog>
    </Container>
  );
};

export default BusList;
