import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axiosInstance from '../Admin/axiosInstance';
import './../../App.css'; 

const UserReports = () => {
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('username');
  const role = localStorage.getItem('role');
  const [expenseData, setExpenseData] = useState(null);
  const [passengerBookingData, setPassengerBookingData] = useState(null);
  const [consignmentBookingData, setConsignmentBookingData] = useState(null);
  const [loading, setLoading] = useState(true);

  const localDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const passengerResponse = await axiosInstance.get(`/reservation/statistics/${userId}/${localDate}`);
        setPassengerBookingData(passengerResponse.data);

        const consignmentResponse = await axiosInstance.get(`/parcel/statistics/${userId}/${localDate}`);
        setConsignmentBookingData(consignmentResponse.data);

        const expenseResponse = await axiosInstance.get(`/expense/statistics/${localDate}/${userId}`);
        setExpenseData(expenseResponse.data);
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, localDate]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!passengerBookingData || !consignmentBookingData) {
    return <Typography variant="body1">Data not available</Typography>;
  }

  return (
    <Container style={{ padding: '2rem' }}>
      {/* Common Details at the Top */}
      <Typography variant="h5" gutterBottom>
        Report Summary
      </Typography>
      <Typography variant="body1" gutterBottom>
        Date: {localDate}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Username: {userName}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Role: {role}
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '2rem' }}>
        {/* Passenger Booking */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography variant="h6">Passenger Booking (view all)</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Bookings</TableCell>
                  <TableCell>{passengerBookingData.totalReservations || 0}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(passengerBookingData.totalPriceByModeOfPayment || {}).map(([mode, price]) => (
                  <TableRow key={mode}>
                    <TableCell>{mode}</TableCell>
                    <TableCell>{price} KES</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Total Price by User</TableCell>
                  <TableCell>{passengerBookingData.totalPriceByUser || 0} KES</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Consignment Booking */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography variant="h6">Consignment Booking (view all)</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Parcels</TableCell>
                  <TableCell>{consignmentBookingData.totalParcels || 0}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(consignmentBookingData.totalPriceByModeOfPayment || {}).map(([mode, price]) => (
                  <TableRow key={mode}>
                    <TableCell>{mode}</TableCell>
                    <TableCell>{price} KES</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Total Price by User</TableCell>
                  <TableCell>{consignmentBookingData.totalPriceByUser || 0} KES</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Expense Section */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography variant="h6">Expenses</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Expenses</TableCell>
                  <TableCell>{expenseData || 0} KES</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserReports;
