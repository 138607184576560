import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  CircularProgress,
  Box,
} from '@mui/material';
import { CompareArrows } from '@mui/icons-material';
import axiosInstance from '../Admin/axiosInstance';

const CashForward = () => {
  const userId = localStorage.getItem('userId');
  const username = localStorage.getItem('username');
  const role = localStorage.getItem('role');

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [cashForwardDialogOpen, setCashForwardDialogOpen] = useState(false);
  const [cashForwardData, setCashForwardData] = useState({
    totalAmount: 0,
    mpesa: 0,
    cash: '',
    voucher: 0,
    receiverName: '',
    senderName: username || '',
  });

  const [passengerBookingData, setPassengerBookingData] = useState({});
  const [consignmentBookingData, setConsignmentBookingData] = useState({});
  const [expenseData, setExpenseData] = useState({});

  const currentDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [passengerRes, consignmentRes, expenseRes] = await Promise.all([
          axiosInstance.get(`/reservation/statistics/${userId}/${currentDate}`),
          axiosInstance.get(`/parcel/statistics/${userId}/${currentDate}`),
          axiosInstance.get(`/expense/statistics/${currentDate}/${userId}`),
        ]);

        setPassengerBookingData(passengerRes.data);
        setConsignmentBookingData(consignmentRes.data);
        setExpenseData(expenseRes.data);

        // Calculate Mpesa, Voucher, and Cash amounts
        const mpesaAmount =
          (passengerRes.data.totalPriceByModeOfPayment?.Mpesa || 0) +
          (consignmentRes.data.totalPriceByModeOfPayment?.Mpesa || 0);

        const voucherAmount =
          (passengerRes.data.totalPriceByModeOfPayment?.Voucher || 0) +
          (consignmentRes.data.totalPriceByModeOfPayment?.Voucher || 0);

        const cashAmount =
          (passengerRes.data.totalPriceByModeOfPayment?.Cash || 0) +
          (consignmentRes.data.totalPriceByModeOfPayment?.Cash || 0);

        setCashForwardData((prevData) => ({
          ...prevData,
          mpesa: mpesaAmount,
          voucher: voucherAmount,
          cash: cashAmount,
          totalAmount: mpesaAmount + voucherAmount + cashAmount,
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, currentDate, username]);

  const handleCashForwardClick = async () => {
    try {
      const usersResponse = await axiosInstance.get('/users/viewallusers');
      setUserList(usersResponse.data);
      setCashForwardDialogOpen(true);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleCashForwardChange = (e) => {
    const { name, value } = e.target;
    setCashForwardData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === 'cash') {
        const cashValue = parseFloat(value) || 0;
        updatedData.totalAmount =
          cashValue + prevData.mpesa + prevData.voucher;
      }

      return updatedData;
    });
  };

  const handleCashForwardSubmit = async () => {
    try {
      if (!cashForwardData.receiverName || cashForwardData.cash === '') {
        alert('Please fill in all required fields.');
        return;
      }

      await axiosInstance.post('/cashforward/add', cashForwardData);
      alert('Cash Forward data submitted successfully.');
      setCashForwardDialogOpen(false);
      // Reset cash amount
      setCashForwardData((prevData) => ({
        ...prevData,
        cash: '',
        totalAmount: prevData.mpesa + prevData.voucher,
        receiverName: '',
      }));
    } catch (error) {
      console.error('Error submitting Cash Forward data:', error);
      alert('Failed to submit Cash Forward data.');
    }
  };

  const handleCashForwardDialogClose = () => {
    setCashForwardDialogOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '20px' }}>
      <Paper sx={{ padding: '20px', marginBottom: '30px' }}>
        <Typography variant="h5" sx={{ marginBottom: '10px', color: '#1E90FF' }}>
          Financial
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '20px' }}>
          All your accounts
        </Typography>
        <Grid container spacing={2}>
          {/* Display Total Cash and Expenses */}
          <Grid item xs={12}>
            <Typography variant="h6">Total Cash: KES {cashForwardData.cash}</Typography>
            <Typography variant="h6">Total Expenses: KES {expenseData.totalExpenses || 0}</Typography>
          </Grid>

          {/* Cash Forward Button */}
          <Grid item xs={6} sm={4} md={3}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CompareArrows />}
              sx={{ textTransform: 'none' }}
              onClick={handleCashForwardClick}
            >
              Cash Forward
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Cash Forward Dialog */}
      <Dialog
        open={cashForwardDialogOpen}
        onClose={handleCashForwardDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Cash Forward Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mpesa Amount"
                name="mpesa"
                value={cashForwardData.mpesa}
                fullWidth
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Voucher Amount"
                name="voucher"
                value={cashForwardData.voucher}
                fullWidth
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Cash Amount"
                name="cash"
                value={cashForwardData.cash}
                onChange={handleCashForwardChange}
                fullWidth
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Total Amount"
                name="totalAmount"
                value={cashForwardData.totalAmount}
                fullWidth
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Sender Name"
                name="senderName"
                value={cashForwardData.senderName}
                fullWidth
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Receiver Name"
                name="receiverName"
                value={cashForwardData.receiverName}
                onChange={handleCashForwardChange}
                fullWidth
                variant="outlined"
              >
                {userList.map((user) => (
                  <MenuItem key={user.id} value={user.username}>
                    {user.username}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCashForwardDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCashForwardSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CashForward;
