import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance'; 
import {
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    Drawer,
    IconButton
} from '@mui/material';

const ParcelRegistrationForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        senderName: '',
        senderPhone: '',
        receiverName: '',
        receiverPhone: '',
        recipientAddress: '',
        parcelContent: '',
        price: '',
        source: '',
        destination: '',
        modeOfPayment: '',
        parcelWeight: '', // Existing field
        itemsCount: '', // New field
        bookDate: '', // Existing field
        status: 'INPROGRESS',
        direction: ''
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const getDirection = (source, destination) => {
        const locations = [
            'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
            'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
            'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
        ];
        
        const sourceIndex = locations.indexOf(source);
        const destinationIndex = locations.indexOf(destination);
    
        if (sourceIndex < destinationIndex) {
            return `${source} - ${destination}`;
        } else {
            return `${destination} - ${source}`;
        }
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: value
    //     }));
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, [name]: value };
            
            if (name === 'source' || name === 'destination') {
                updatedFormData.direction = getDirection(updatedFormData.source, updatedFormData.destination);
            }
            
            return updatedFormData;
        });
    };
    

    const validateForm = () => {
        const newErrors = {};
        const phoneRegex = /^0\d{9}$/;

        if (!formData.senderName) newErrors.senderName = 'Sender name is required';

        if (!formData.senderPhone) {
            newErrors.senderPhone = 'Sender phone is required';
        } else if (!phoneRegex.test(formData.senderPhone)) {
            newErrors.senderPhone = 'Sender phone must be 10 digits and start with 0';
        }

        if (!formData.receiverName) newErrors.receiverName = 'Recipient name is required';

        if (!formData.receiverPhone) {
            newErrors.receiverPhone = 'Recipient phone is required';
        } else if (!phoneRegex.test(formData.receiverPhone)) {
            newErrors.receiverPhone = 'Recipient phone must be 10 digits and start with 0';
        }
        if (!formData.recipientAddress) newErrors.recipientAddress = 'Recipient address is required';
        if (!formData.parcelContent) newErrors.parcelContent = 'Parcel content is required';
        if (!formData.price) newErrors.price = 'Price is required';
        if (!formData.source) newErrors.source = 'Source is required';
        if (!formData.destination) newErrors.destination = 'Destination is required';
        if (!formData.modeOfPayment) newErrors.modeOfPayment = 'Mode of payment is required';
        if (!formData.parcelWeight) newErrors.parcelWeight = 'Parcel weight is required'; // Existing validation
        if (!formData.itemsCount) newErrors.itemsCount = 'Items count is required'; // New validation
        if (!formData.bookDate) {
            newErrors.bookDate = 'Booking date is required';
        } else if (new Date(formData.bookDate) < new Date().setHours(0, 0, 0, 0)) {
            newErrors.bookDate = 'Booking date cannot be in the past';
        }
        return newErrors;
    };

    const generatetrackingCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let trackingCode = 'DCL'; // Initialize with 'DCL'
        for (let i = 0; i < 6; i++) {
            trackingCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return trackingCode;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        
        const trackingCode = generatetrackingCode();
        const dataToSend = { ...formData, trackingCode };
    
        try {
            await axiosInstance.post('/parcel/add', dataToSend);
            setSuccessMessage(`Parcel registered successfully! Tracking Number: ${trackingCode}`);
            setErrorMessage('');
            navigate('/receipt', { state: { trackingCode, ...formData } });
            setFormData({
                senderName: '',
                senderPhone: '',
                receiverName: '',
                receiverPhone: '',
                recipientAddress: '',
                parcelContent: '',
                price: '',
                source: '',
                destination: '',
                modeOfPayment: '',
                parcelWeight: '',
                itemsCount: '',
                bookDate: '',
                status: 'INPROGRESS',
                direction: ''  // Clear the direction
            });
        } catch (error) {
            setErrorMessage('There was an error registering the parcel.');
            setSuccessMessage('');
        }
    };
    
    const locations = [
        'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
        'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
        'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
    ];

    const paymentOptions = [
        'Cash', 'Mpesa'
    ];

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Parcel Registration
                </Typography>
                {successMessage && <Typography color="primary">{successMessage}</Typography>}
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Existing form fields */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Sender Name"
                                name="senderName"
                                value={formData.senderName}
                                onChange={handleChange}
                                error={!!errors.senderName}
                                helperText={errors.senderName}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Sender Phone"
                                name="senderPhone"
                                value={formData.senderPhone}
                                onChange={handleChange}
                                error={!!errors.senderPhone}
                                helperText={errors.senderPhone}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Recipient Name"
                                name="receiverName"
                                value={formData.receiverName}
                                onChange={handleChange}
                                error={!!errors.receiverName}
                                helperText={errors.receiverName}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Recipient Phone"
                                name="receiverPhone"
                                value={formData.receiverPhone}
                                onChange={handleChange}
                                error={!!errors.receiverPhone}
                                helperText={errors.receiverPhone}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Recipient Address"
                                name="recipientAddress"
                                value={formData.recipientAddress}
                                onChange={handleChange}
                                error={!!errors.recipientAddress}
                                helperText={errors.recipientAddress}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Parcel Content"
                                name="parcelContent"
                                value={formData.parcelContent}
                                onChange={handleChange}
                                error={!!errors.parcelContent}
                                helperText={errors.parcelContent}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Price"
                                name="price"
                                type="number"
                                value={formData.price}
                                onChange={handleChange}
                                error={!!errors.price}
                                helperText={errors.price}
                                required
                            />
                        </Grid>
                        {/* Source and Destination */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required error={!!errors.source}>
                                <InputLabel id="source-label">Source</InputLabel>
                                <Select
                                    labelId="source-label"
                                    name="source"
                                    value={formData.source}
                                    onChange={handleChange}
                                    label="Source"
                                >
                                    {locations.map((location, index) => (
                                        <MenuItem key={index} value={location}>
                                            {location}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.source && <FormHelperText>{errors.source}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required error={!!errors.destination}>
                                <InputLabel id="destination-label">Destination</InputLabel>
                                <Select
                                    labelId="destination-label"
                                    name="destination"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    label="Destination"
                                >
                                    {locations.map((location, index) => (
                                        <MenuItem key={index} value={location}>
                                            {location}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.destination && <FormHelperText>{errors.destination}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        {/* Direction Display */}
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Direction"
                            name="direction"
                            value={formData.direction}
                            disabled
                        />
                    </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required error={!!errors.modeOfPayment}>
                                <InputLabel id="modeOfPayment-label">Mode of Payment</InputLabel>
                                <Select
                                    labelId="modeOfPayment-label"
                                    name="modeOfPayment"
                                    value={formData.modeOfPayment}
                                    onChange={handleChange}
                                    label="Mode of Payment"
                                >
                                    {paymentOptions.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.modeOfPayment && <FormHelperText>{errors.modeOfPayment}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        {/* New Items Count field */}
                        <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Items Count"
                            name="itemsCount"
                            type="number"
                            value={formData.itemsCount}
                            onChange={handleChange}
                            error={!!errors.itemsCount}
                            helperText={errors.itemsCount}
                            required
                        />
                    </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Parcel Weight"
                                name="parcelWeight"
                                value={formData.parcelWeight}
                                onChange={handleChange}
                                error={!!errors.parcelWeight}
                                helperText={errors.parcelWeight}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Booking Date"
                                name="bookDate"
                                type="date"
                                value={formData.bookDate}
                                onChange={handleChange}
                                error={!!errors.bookDate}
                                helperText={errors.bookDate}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Register Parcel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default ParcelRegistrationForm;
