import React, { useState } from 'react';
import { TextField, Button, Grid, Container, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const locations = [
  'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa', 
  'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
  'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
];

const SearchForm = ({ onSearch }) => {
  const [source, setSource] = useState('');
  const [destination, setDestination] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch({ source, destination, date });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth required>
              <InputLabel>From</InputLabel>
              <Select
                value={source}
                onChange={(e) => setSource(e.target.value)}
                label="From"
              >
                {locations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth required>
              <InputLabel>Destination</InputLabel>
              <Select
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                label="Destination"
              >
                {locations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default SearchForm;
