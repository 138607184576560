import React, { useState, useEffect } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Alert, TablePagination } from '@mui/material';
import axiosInstance from './axiosInstance';

const ExpenseTable = () => {
  const [expenses, setExpenses] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Pagination: number of rows per page

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/viewallusers');
      if (response.data.length === 0) {
        setError('No users found.');
      } else {
        setUsers(response.data);
        setError(null);  // Clear any previous error
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users.');
    }
  };

  const fetchExpenses = async () => {
    if (!selectedUser || !date) {
      setError('Please select a user and a date.');
      return;
    }

    try {
      const response = await axiosInstance.get(`/expense/user/${selectedUser}/${date}`);
      if (response.data.length === 0) {
        setError('No expenses found for the selected user and date.');
      } else {
        setExpenses(response.data);
        setError(null);
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
      setError('Error fetching expenses.');
    }
  };

  const updateStatus = async (id, status) => {
    try {
      await axiosInstance.patch(`/expense/update/${id}`, { status });
      fetchExpenses();
    } catch (error) {
      console.error('Error updating expense status:', error);
      setError('Error updating expense status.');
    }
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Expense List
      </Typography>

      <Typography variant="h6" gutterBottom>
        Expenses per User
      </Typography>

      <FormControl fullWidth margin="normal" sx={{ maxWidth: 300 }}>
        <InputLabel id="select-user-label">Select User</InputLabel>
        <Select
          labelId="select-user-label"
          value={selectedUser}
          onChange={handleUserChange}
          label="Select User"
          sx={{ width: '100%' }}
        >
          {users.length > 0 ? (
            users.map((user) => (
              <MenuItem key={user.idNumber} value={user.idNumber}>
                {user.userName} ({user.role}) ({user.location})
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No users available</MenuItem>
          )}
        </Select>
      </FormControl>

      <TextField
        label="Select Date"
        type="date"
        value={date}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        margin="normal"
        sx={{ maxWidth: 300 }}
      />

      <Button variant="contained" color="primary" onClick={fetchExpenses} sx={{ marginTop: 2 }}>
        Fetch Expenses
      </Button>

      {error && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>ID</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Category</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Total Price</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Description</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Date</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Quantity</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Unit</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Price Per Unit</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Approver</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Status</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.length > 0 ? (
              expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((expense) => (
                <TableRow key={expense.id}>
                  <TableCell>{expense.id}</TableCell>
                  <TableCell>{expense.category || '-'}</TableCell>
                  <TableCell>{expense.totalPrice}</TableCell>
                  <TableCell>{expense.description || '-'}</TableCell>
                  <TableCell>{expense.date}</TableCell>
                  <TableCell>{expense.quantity !== null ? expense.quantity : '-'}</TableCell>
                  <TableCell>{expense.unit || '-'}</TableCell>
                  <TableCell>{expense.pricePerUnit !== null ? expense.pricePerUnit : '-'}</TableCell>
                  <TableCell>{expense.approver || '-'}</TableCell>
                  <TableCell>{expense.status}</TableCell>
                  <TableCell>
                    {expense.status === 'PENDING' && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => updateStatus(expense.id, 'APPROVED')}
                          sx={{ marginRight: 1 }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => updateStatus(expense.id, 'REJECTED')}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  No expenses available for the selected user and date.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={expenses.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default ExpenseTable;
