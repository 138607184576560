import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Button, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Drawer, Box, Reports,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem } from '@mui/material';
import { Add, MonetizationOn, AccountBalance, Money, Receipt, CompareArrows, AccountBalanceWallet, FormatListBulleted, LocalAtm, Report, Description, LocalShipping } from '@mui/icons-material';
import axiosInstance from '../Admin/axiosInstance';
import UserReports from './UserReports';
import CashForward from './CashForward';
import './../../App.css'; 


const Dashboard = ({ userId, role }) => {
  const [expenses, setExpenses] = useState([]);
  const [open, setOpen] = useState(false);
  const [showExpenseTable, setShowExpenseTable] = useState(false);
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cashForwardDialogOpen, setCashForwardDialogOpen] = useState(false);
  const [cashForwardData, setCashForwardData] = useState([]);
  const [showCashForwardTable, setShowCashForwardTable] = useState(false);
  const [username, setUsername] = useState('');


  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);  // Set the state with the stored username
    } else {
      console.error('No username found in localStorage');
    }
  }, []);
 

  const [formValues, setFormValues] = useState({
    category: '',
    description: '',
    unit: '',
    quantity: '',
    pricePerUnit: '',
    totalPrice: '',
    status: 'PENDING'
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleCashForwardClick = () => {
    setCashForwardDialogOpen(true);
  };

  const handleCashForwardDialogClose = () => {
    setCashForwardDialogOpen(false);
  };
  
  const handleDateDialogOpen = () => {
    setDateDialogOpen(true);
  };

  const handleDateDialogClose = () => {
    setDateDialogOpen(false);
  };

  const handleDateSelect = (date) => {
    try {
      if (!date) {
        throw new Error('Invalid date selected.');
      }
  
      const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      setSelectedDate(formattedDate);
      fetchExpenses(formattedDate);
    } catch (error) {
      console.error('Error handling date select:', error);
      alert('An error occurred while selecting the date. Please try again.');
    }
  };
  
  const fetchExpenses = (date) => {
    try {
      const userId = localStorage.getItem('userId');
  
      if (!userId) {
        throw new Error('User ID not found. Please ensure you are logged in.');
      }
  
      if (!date) {
        throw new Error('Invalid date provided. Please select a valid date.');
      }
  
      axiosInstance.get(`/expense/user/${userId}/${date}`)
        .then(response => {
          if (response.status === 200) {
            if (response.data && response.data.length > 0) {
              setExpenses(response.data);
              setShowExpenseTable(true);
            } else {
              // Notify the user if there's no data for the selected date
              setExpenses([]);  // Clear any previous expenses data
              setShowExpenseTable(false);
              alert('No expenses found for the selected date.');
            }
          } else {
            throw new Error('Failed to fetch expenses.');
          }
        })
        .catch(error => {
          console.error('Error fetching expenses:', error);
          alert('An error occurred while fetching expenses. Please try again later.');
        });
    } catch (error) {
      console.error('Error in fetchExpenses:', error);
      alert('An error occurred while preparing to fetch expenses. Please try again.');
    }
  };

  // localStorage.setItem('username', username);
  
  // Fetch cash forward status when button is clicked
  const handleCashForwardStatus = async () => {
    try {
      if (!username) {
        throw new Error('Username not found. Please ensure you are logged in.');
      }

      const response = await axiosInstance.get(`/cashforward/sender/${username}`);
      
      console.log(response.data);  // Log the API response to see the structure
  
      // Ensure that the data you're setting is an array
      if (Array.isArray(response.data)) {
        setCashForwardData(response.data);
      } else {
        // Handle the case where the response is not an array
        console.error('Expected an array, but received:', response.data);
        alert('Unexpected data format. Please contact support.');
      }
  
      setShowCashForwardTable(true);
    } catch (error) {
      console.error('Error fetching cash forward status:', error);
      alert('An error occurred while fetching cash forward status. Please try again.');
    }
  };
  
  // Function to apply color coding based on cashForwardStatus
  const getStatusColor = (status) => {
    switch (status) {
      case 'APPROVE':
        return 'green';
      case 'PENDING':
        return 'orange';
      case 'REJECTED':
        return 'red';
      default:
        return 'black';
    }
  };
  
  const getBackgroundColor = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'green';
      case 'PENDING':
        return 'yellow';
      case 'REJECTED':
        return 'red';
      default:
        return 'lightgray'; // Default color if status is null or unknown
    }
  };

  // Calculate totalPrice whenever quantity or pricePerUnit changes
  useEffect(() => {
    const totalPrice = formValues.quantity * formValues.pricePerUnit;
    setFormValues(prevValues => ({
      ...prevValues,
      totalPrice: totalPrice
    }));
  }, [formValues.quantity, formValues.pricePerUnit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = () => {
    axiosInstance.post('/expense/add', formValues)
      .then(response => {
        alert('Expense added successfully!');
        setExpenses([...expenses, response.data]);
        setOpen(false);
      })
      .catch(error => {
        alert('Failed to add expense');
        console.error('Error adding expense:', error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '20px' }}>
      {/* Expenses Section */}
      <Paper sx={{ padding: '20px', marginBottom: '30px' }}>
        <Typography variant="h5" sx={{ marginBottom: '10px', color: '#1E90FF' }}>
          Expenses
        </Typography>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleClickOpen}>
          Add Expense
        </Button>
      </Paper>

      {/* Add Expense Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Expense</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            select
            label="Category"
            name="category"
            value={formValues.category}
            onChange={handleInputChange}
            margin="normal"
          >
            <MenuItem value="Car Fuel">Car Fuel</MenuItem>
            <MenuItem value="Spares">Spares</MenuItem>
            <MenuItem value="Wages">Wages</MenuItem>
            <MenuItem value="Rent">Rent</MenuItem>
            <MenuItem value="Salaries">Salaries</MenuItem>
            <MenuItem value="Staff Meals">Staff Meals</MenuItem>
            <MenuItem value="Internet">Internet</MenuItem>
            <MenuItem value="ICT components">ICT Components</MenuItem>
            <MenuItem value="Cleaning Services">Staff Meals</MenuItem>
            <MenuItem value="Power Components">Power Components</MenuItem>
            <MenuItem value="Water Bills">Water Bills</MenuItem>
            <MenuItem value="Electricity Bill">Electricity Bill</MenuItem>
            <MenuItem value="Stationery">Stationery</MenuItem>
            <MenuItem value="Tyres">Tyres</MenuItem>
            <MenuItem value="Stationery">Stationery</MenuItem>
            <MenuItem value="Other Vehicle Expenses">Other Vehicle Expenses</MenuItem>
            <MenuItem value="Labour Charges">Labour Charges</MenuItem>
            <MenuItem value="CSR">Corporate social responsibility</MenuItem>
            
          </TextField>
          <TextField
            fullWidth
            label="Item Description"
            name="description"
            type="text"
            value={formValues.description}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Quantity"
            name="quantity"
            type="number"
            value={formValues.quantity}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            select
            label="Unit"
            name="unit"
            value={formValues.unit}
            onChange={handleInputChange}
            margin="normal"
          >
            <MenuItem value="Piece">Piece</MenuItem>
            <MenuItem value="Liter">Liter</MenuItem>
            <MenuItem value="Kilogram">Kilogram</MenuItem>
            <MenuItem value="Meters">Meters</MenuItem>
            <MenuItem value="PCs">PCs</MenuItem>
            <MenuItem value="Bandwith">Bandwith</MenuItem>
            <MenuItem value="Kwh">Kwh</MenuItem>
            <MenuItem value="Ream">Ream</MenuItem>
            <MenuItem value="Ream">Roll</MenuItem>
            <MenuItem value="Lot">Lot</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Price Per Unit"
            name="pricePerUnit"
            type="number"
            value={formValues.pricePerUnit}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Total Price"
            name="totalPrice"
            value={formValues.totalPrice}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reconciliation Section */}
      <Paper sx={{ padding: '20px', marginBottom: '30px' }}>
        <Typography variant="h5" sx={{ marginBottom: '10px', color: '#1E90FF' }}>
          Financial
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '20px' }}>
          All your accounts
        </Typography>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<MonetizationOn />}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 200, 
              height: 60, 
              minWidth: 200, 
              minHeight: 60, 
              justifyContent: 'center', 
              textAlign: 'center', 
              borderRadius: 2 
            }}
            onClick={toggleDrawer(true)}
          >
            My income statement
          </Button>
        </Grid>
        <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '80%',
            maxWidth: '750px',
            height: '80vh', // Set the height of the drawer
            margin: 'auto',
            marginTop: '3%',
            borderRadius: 3,
            padding: 2,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
          <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto', // Ensure overflow content is scrollable
            flex: 1, // Allow content to grow and take available space
          }}
            // sx={{ width: 750 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <UserReports userId={userId} username={username} role={role} />
          </Box>
        </Drawer>
         
          <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="outlined"
                fullWidth
                startIcon={<CompareArrows />}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 200, 
                  height: 60, 
                  minWidth: 200, 
                  minHeight: 60, 
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  borderRadius: 2 
                }}
                onClick={handleCashForwardClick}
              >
                Cash Forward
              </Button>
            </Grid>
            {/* Add other buttons as needed */}
          {/* </Grid> */}
        

        {/* Cash Forward Dialog */}
        <Dialog
          open={cashForwardDialogOpen}
          onClose={handleCashForwardDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <CashForward onClose={handleCashForwardDialogClose} />
        </Dialog>

        <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Money />}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 200, 
                height: 60, 
                minWidth: 200, 
                minHeight: 60, 
                justifyContent: 'center', 
                textAlign: 'center', 
                borderRadius: 2 
              }}
              onClick={handleDateDialogOpen}  // Open the date dialog
            >
              Expense
            </Button>
          </Grid>
           {/* Date Dialog for selecting a date */}
            <DateDialog
              open={dateDialogOpen}
              onClose={handleDateDialogClose}
              onDateSelect={handleDateSelect}
            />

          {/* <Grid container spacing={2}> */}
          {/* Button to fetch cash forward status */}
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CompareArrows />}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 200, 
                height: 60, 
                minWidth: 100, 
                minHeight: 60, 
                justifyContent: 'center', 
                textAlign: 'center', 
                borderRadius: 2 
              }}
              onClick={handleCashForwardStatus}
            >
              Cash Forward Status
            </Button>
          </Grid>
        {/* </Grid> */}

        {/* Display Cash Forward Data Table */}
          {showCashForwardTable && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h5">Cash Forward Status</Typography>
              <TableContainer component={Paper}>
                <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#1976d2' }}> {/* Apply background color to the header */}
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>ID</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Total Amount</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Mpesa</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Cash</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Voucher</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Receiver</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Sender</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {cashForwardData.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.totalAmount}</TableCell>
                        <TableCell>{item.mpesa}</TableCell>
                        <TableCell>{item.cash}</TableCell>
                        <TableCell>{item.voucher}</TableCell>
                        <TableCell>{item.receiverName}</TableCell>
                        <TableCell>{item.senderName}</TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell style={{ color: getStatusColor(item.cashForwardStatus) }}>
                          {item.cashForwardStatus}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {/* <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" fullWidth startIcon={<FormatListBulleted />} sx={{ textTransform: 'none' }}>
              Branch Status
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" fullWidth startIcon={<AccountBalanceWallet />} sx={{ textTransform: 'none' }}>
              Cash Received
            </Button>
          </Grid> */}
          {/* <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" fullWidth startIcon={<AccountBalance />} sx={{ textTransform: 'none' }}>
              Branch Status
            </Button>
          </Grid> */}
          {/* <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" fullWidth startIcon={<LocalAtm />} sx={{ textTransform: 'none' }}>
              Mpesa
            </Button>
          </Grid> */}
        </Grid>
      </Paper>

      {/* Expense Table */}
       {/* Expense Table */}
       {showExpenseTable && (
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Item Description</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price Per Unit</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Approver</TableCell>
                <TableCell>Approval Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses.map((expense, index) => (
                <TableRow key={index}>
                  <TableCell>{expense.category}</TableCell>
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>{expense.unit}</TableCell>
                  <TableCell>{expense.quantity}</TableCell>
                  <TableCell>{expense.pricePerUnit}</TableCell>
                  <TableCell>{expense.totalPrice}</TableCell>
                  <TableCell>{expense.approver}</TableCell>
                  {/* <TableCell>{expense.status}</TableCell> */}
                  <TableCell
                    style={{
                      backgroundColor: getBackgroundColor(expense.status || 'Pending'), // Fallback to 'Pending' if status is null
                      color: expense.status === 'Pending' ? 'black' : 'white',
                    }}
                  >
                    {expense.status || 'N/A'}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
  
};

// DateDialog Component
const DateDialog = ({ open, onClose, onDateSelect }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSelectClick = () => {
    if (selectedDate) {
      onDateSelect(new Date(selectedDate));
      onClose(); // Close the dialog after selecting the date
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Date</DialogTitle>
      <DialogContent>
        <TextField
          type="date"
          value={selectedDate || ''}
          onChange={handleDateChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSelectClick} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default Dashboard;
