import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { Box, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, IconButton, Typography, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, TablePagination } from '@mui/material';
import { Delete, Edit, AddCircle } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageBusSchedule = () => {
  const [schedules, setSchedules] = useState([]);
  const [buses, setBuses] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [scheduleForm, setScheduleForm] = useState({
    source: '',
    destination: '',
    busRegistration: '',
    scheduleDate: '',
    scheduleTime: '',
    price: '',
  });
  const [editSchedule, setEditSchedule] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchSchedules();
    fetchBuses();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axiosInstance.get('/schedule/viewallschedules');
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const fetchBuses = async () => {
    try {
      const response = await axiosInstance.get('/bus/viewAll');
      setBuses(response.data);
    } catch (error) {
      console.error('Error fetching buses:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScheduleForm({
      ...scheduleForm,
      [name]: value
    });
  };

  const handleAddSchedule = async () => {
    try {
      if (editSchedule) {
        await axiosInstance.put(`/schedule/updateschedule/${editSchedule.scheduleId}`, scheduleForm);
        setSuccessMessage('Bus schedule updated successfully!');
      } else {
        await axiosInstance.post('/schedule/add', scheduleForm);
        setSuccessMessage('Bus schedule added successfully!');
      }
      fetchSchedules(); 
      setDialogOpen(false); 
      setScheduleForm({
        source: '',
        destination: '',
        busRegistration: '',
        scheduleDate: '',
        scheduleTime: '',
        price: '',
      });
      setEditSchedule(null);
    } catch (error) {
      console.error('Error adding/updating schedule:', error);
    }
  };

  const handleEditSchedule = (schedule) => {
    setEditSchedule(schedule);
    setScheduleForm({
      source: schedule.source,
      destination: schedule.destination,
      busRegistration: schedule.busRegistration,
      scheduleDate: schedule.scheduleDate,
      scheduleTime: schedule.scheduleTime,
      price: schedule.price,
    });
    setDialogOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await axiosInstance.delete(`/schedule/delete/${scheduleId}`);
      fetchSchedules(); 
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button 
          variant="contained"
          color="primary"
          startIcon={<AddCircle />} 
          onClick={() => setDialogOpen(true)}
        >
          Schedule Bus
        </Button>
      </Box>
      <Typography variant="h6" gutterBottom>Scheduled Buses</Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
        <TableHead sx={{ backgroundColor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: '#fff' }}>Source</TableCell>
              <TableCell sx={{ color: '#fff' }}>Destination</TableCell>
              <TableCell sx={{ color: '#fff' }}>Registration Number</TableCell>
              <TableCell sx={{ color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((schedule) => (
              <TableRow key={schedule.scheduleId}>
                <TableCell>{schedule.source}</TableCell>
                <TableCell>{schedule.destination}</TableCell>
                <TableCell>{schedule.busRegistration}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditSchedule(schedule)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteSchedule(schedule.scheduleId)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={schedules.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{editSchedule ? 'Edit Schedule' : 'Add Schedule'}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400 }}>
            <TextField label="Source" name="source" value={scheduleForm.source} onChange={handleInputChange} required />
            <TextField label="Destination" name="destination" value={scheduleForm.destination} onChange={handleInputChange} required />
            <TextField
              label="Registration Number"
              name="busRegistration"
              value={scheduleForm.busRegistration}
              onChange={handleInputChange}
              required
              select
            >
              {buses.map((bus) => (
                <MenuItem key={bus.id} value={bus.busRegistration}>
                  {bus.busRegistration}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleAddSchedule}>
            {editSchedule ? 'Update Schedule' : 'Add Schedule'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageBusSchedule;
