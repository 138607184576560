import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './Admin/axiosInstance';

const SeatingLayout = ({ bus, onSeatSelect }) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const { travelDate, direction, source, destination, cost, departure } = bus;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch booked and reserved seats if travelDate and direction are provided
    if (travelDate && direction !== undefined) {
      // Fetch booked seats from API
      axiosInstance.get(`/reservation/bookedseats/${travelDate}/${direction}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            setBookedSeats(response.data);
          } else {
            console.error('Unexpected response format for booked seats:', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching booked seats:', error);
        });

      // Fetch reserved seats from API
      axiosInstance.get(`reservation/bookedReserves/${travelDate}/${direction}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            setReservedSeats(response.data);
          } else {
            console.error('Unexpected response format for reserved seats:', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching reserved seats:', error);
        });
    } else {
      console.error('Missing travelDate or direction:', { travelDate, direction });
    }
  }, [travelDate, direction]);

  // Check if bus data is available
  if (!cost || !destination || !source || !travelDate || !departure || direction === undefined) {
    return <Typography variant="h6">Bus information is not available.</Typography>;
  }

  // Handle seat click events
  const handleSeatClick = (seat) => {
    if (!bookedSeats.includes(seat) && !reservedSeats.includes(seat)) {
      setSelectedSeats(prevSeats => 
        prevSeats.includes(seat) ? prevSeats.filter(s => s !== seat) : [...prevSeats, seat]
      );
      if (onSeatSelect) {
        onSeatSelect(seat); 
      }
    }
  };

  // Calculate total price based on selected seats
  const calculateTotalPrice = () => selectedSeats.length * cost;

  // Navigate to booking form with selected seats and total price
  const handleBookNow = () => {
    navigate('/booking-form', {
      state: {
        destination: destination,
        source: source,
        bookDate: travelDate,
        bookTime: departure,
        seats: selectedSeats.join(','),
        price: calculateTotalPrice(),
        direction: direction
      }
    });
  };

  // Render individual rows of seats
  const renderSeatRow = (seats, isLastRow = false) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
      {seats.map((seat, index) => (
        <Button
          key={index}
          onClick={() => seat && handleSeatClick(seat)}
          disabled={seat === '' || bookedSeats.includes(seat) || reservedSeats.includes(seat)}
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 2px',
            borderRadius: '4px',
            backgroundColor: seat === '' 
              ? '#fff45g' 
              : selectedSeats.includes(seat) 
              ? 'green' 
              : bookedSeats.includes(seat) 
              ? 'red' 
              : reservedSeats.includes(seat) 
              ? '#707793' 
              : '#dbbab6',
            '&:hover': {
              backgroundColor: seat === '' 
                ? '#ffffff' 
                : (!bookedSeats.includes(seat) && !reservedSeats.includes(seat) && !selectedSeats.includes(seat) 
                ? '#e0e0e0' 
                : undefined),
            },
            color: seat === '' ? '#ffffff' : '#000',
            fontSize: '0.75rem',
          }}
        >
          {seat && <Typography variant="body2">{seat}</Typography>}
        </Button>
      ))}
      {!isLastRow && <Box sx={{ width: 3 }}></Box>}
    </Box>
  );

  // Seat layout definition
  const seats = [
    ['1D', '2', '', '3', '4D'],
    ['5D', '6', '', '7', '8D'],
    ['9D', '10', '', '11', '12D'],
    ['13D', '14', '', '15', '16D'],
    ['17D', '18', '', '19', '20D'],
    ['21D', '22', '', '23', '24D'],
    ['25D', '26', '', '27', '28D'],
    ['29D', '30', '', '31', '32D'],
    ['33D', '34', '', '35', '36D'],
    ['37D', '38', '', '39', '40D'],
    ['41D', '42', '', '43', '44D'],
    ['45D', '46', '47', '48', '49D']
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Select Your Seat
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: 'green',
                color: '#fff',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
              }}
            >
              <Typography variant="body2">S</Typography>
            </Button>
            <Typography variant="body1">Selected</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: 'red',
                color: '#fff',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              <Typography variant="body2">B</Typography>
            </Button>
            <Typography variant="body1">Booked</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: '#707793',
                color: '#fff',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'darkorange',
                },
              }}
            >
              <Typography variant="body2">R</Typography>
            </Button>
            <Typography variant="body1">Reserved</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: '#dbbab6',
                color: '#000',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <Typography variant="body2">U</Typography>
            </Button>
            <Typography variant="body1">Unbooked</Typography>
          </Grid>
        </Grid>
      </Box>

      </Box>

      {seats.map((row, index) => renderSeatRow(row, index === seats.length - 1))}

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">
          Total Price: Ksh.{calculateTotalPrice()}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBookNow}
          disabled={selectedSeats.length === 0}
        >
          Book Now
        </Button>
      </Box>
    </Box>
  );
};

SeatingLayout.propTypes = {
  bus: PropTypes.shape({
    cost: PropTypes.number.isRequired,
    destination: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    travelDate: PropTypes.string.isRequired,
    departure: PropTypes.string.isRequired,
    direction: PropTypes.number.isRequired,
  }).isRequired,
  onSeatSelect: PropTypes.func.isRequired, 
};

export default SeatingLayout;
