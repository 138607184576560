import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import html2pdf from 'html2pdf.js';
import './../../App.css'; 

const VoucherReceipt = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const receiptDetails = location.state; // Get receiptDetails from location state

  const printTicket = () => {
    const originalBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden'; 

    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print Ticket</title>');
    printWindow.document.write('<style>body{font-family: Arial, sans-serif; margin: 0; padding: 20px;} .ticket-container{width: 100%; max-width: 600px; margin: auto; border: 1px solid #ddd; padding: 20px; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.1);} .qr-code{width: 50px; height: 50px; margin-top: 20px;} .header{font-weight: bold; margin-bottom: 10px;} .row{display: flex; justify-content: space-between; margin-bottom: 8px;} .row div{flex: 1; padding: 0 5px;} .title{font-size: 16px; font-weight: bold; margin-bottom: 10px;} .content{margin-bottom: 20px;}</style></head><body>');
    printWindow.document.write(document.getElementById('ticket-content').innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();

    printWindow.print();
    document.body.style.overflow = originalBodyOverflow; 
    
    printWindow.onafterprint = () => {
      printWindow.close();
      navigate('/home');
    };
  };

  const downloadTicket = () => {
    const element = document.getElementById('ticket-content');
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: 'ticket.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: [3, 5], orientation: 'portrait' } 
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ padding: 2, width: 300, maxWidth: '100%', borderRadius: 2, border: '1px solid #ddd', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <Typography variant="h6" align="center" gutterBottom>
          DreamBus Ltd
        </Typography>
        <Typography variant="body1" align="center" className="title" gutterBottom>
          Vourcher Receipt
        </Typography>
        <Typography variant="body1" align="center" className="title" gutterBottom>
          {receiptDetails.source} &rarr; {receiptDetails.destination}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #ddd', borderRadius: 2, padding: 2 }} id="ticket-content">
          <Typography variant="h5" className="title" gutterBottom>
            {receiptDetails.voucherNo}
          </Typography>
          <Typography variant="body1" className="title" gutterBottom>
            Pickup Location: {receiptDetails.pickUpLocation}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`${receiptDetails.firstName} ${receiptDetails.lastName}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Seat Number: {receiptDetails.seatNumber}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography variant="body1">{`ID: ${receiptDetails.idNumber}`}</Typography>
            <Typography variant="body1">
            Phone: 
            {receiptDetails.phone 
              ? `${receiptDetails.phone.toString().slice(0, 4)}xxxx${receiptDetails.phone.toString().slice(-2)}` 
              : 'Phone number unavailable'}
          </Typography>
            {/* <Typography variant="body1">{`Phone: ${receiptDetails.phone}`}</Typography> */}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography variant="body1">{`Mode of Payment: ${receiptDetails.modeOfPayment}`}</Typography>
            <Typography variant="body1">
              {`Price: Ksh ${receiptDetails.price} `}
              {`Discount: Ksh ${receiptDetails.discount} `}
            </Typography>
          </Box>
          <Typography variant="body1" className="header" gutterBottom>
            Departure
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`${receiptDetails.travelDate} ${receiptDetails.scheduleTime}`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <QRCode value={`Voucher Number: ${receiptDetails.voucherNo}`} size={50} className="qr-code" />
          </Box>
        </Box>
        <Typography variant="body1" gutterBottom>
            Expiry Date: {receiptDetails.expiryDate}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={printTicket}>
            Print
          </Button>
          <Button variant="contained" color="secondary" onClick={downloadTicket}>
            Download
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default VoucherReceipt;
