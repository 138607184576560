import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, isAuthenticated, role, username, userId, handleLogout }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Component role={role} username={username} userId={userId} handleLogout={handleLogout} />;
};

export default PrivateRoute;
