import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axiosInstance from './Admin/axiosInstance'; 
import logoImage from '../images/home1.png';

const PasswordResetPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace with your password reset API endpoint
      await axiosInstance.post('/auth/reset-password', { email });
      setMessage('Password reset link sent to your email!');
      setError('');  // Clear any previous error
    } catch (err) {
      setError('Failed to send reset link. Please check your email address.');
      setMessage('');  // Clear any previous message
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',  // Grey background for the entire screen
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          maxWidth: 400,
          width: '100%',
          backgroundColor: '#fff',  // White background for the form
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src={logoImage} alt="Logo" style={{ height: 70 }} /> 
        </Box>
        <Typography variant="h5" align="center">Reset Password</Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            margin="normal"
            required
          />
          {message && <Typography color="success.main" align="center" sx={{ mt: 2 }}>{message}</Typography>}
          {error && <Typography color="error.main" align="center" sx={{ mt: 2 }}>{error}</Typography>}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Send Reset Link
          </Button>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link component={RouterLink} to="/" variant="body2">
              Login?
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PasswordResetPage;
