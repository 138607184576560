import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  CircularProgress,
  MenuItem,
  Select,
  Button,
  InputLabel,
  FormControl,
  TextField,
  Alert,
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';
import axiosInstance from './axiosInstance';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const ViewReports = () => {
  const [users, setUsers] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedBus, setSelectedBus] = useState(null);
  const [passengerBookingData, setPassengerBookingData] = useState(null);
  const [consignmentBookingData, setConsignmentBookingData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [localDate] = useState(new Date().toISOString().split('T')[0]);
  const [expenseData, setExpenseData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [parcelTax, setParcelTax] = useState(null);
  const [reservationTax, setReservationTax] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchSchedules();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/viewallusers');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchSchedules = async () => {
    try {
      const response = await axiosInstance.get('/schedule/viewallschedules');
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const fetchUserReports = async (userId) => {
    setLoading(true);
    try {
      const passengerResponse = await axiosInstance.get(`/reservation/statistics/${userId}/${localDate}`);
      setPassengerBookingData(passengerResponse.data);

      const consignmentResponse = await axiosInstance.get(`/parcel/statistics/${userId}/${localDate}`);
      setConsignmentBookingData(consignmentResponse.data);

      const expenseResponse = await axiosInstance.get(`/expense/statistics/${localDate}/${userId}`);
      setExpenseData(expenseResponse.data);
    } catch (error) {
      setError('Error fetching user report data');
      console.error('Error fetching user report data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTaxReports = async () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates.');
      return;
    }

    setLoading(true);
    try {
      const parcelTaxResponse = await axiosInstance.get(`/parcel/tax/${startDate}/${endDate}`);
      const reservationTaxResponse = await axiosInstance.get(`/reservation/tax/${startDate}/${endDate}`);
      
      setParcelTax(parcelTaxResponse.data);
      setReservationTax(reservationTaxResponse.data);
      setError(null); // Clear any previous error
    } catch (error) {
      setError('Error fetching tax reports');
      console.error('Error fetching tax reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUser(userId);
    fetchUserReports(userId);
  };

  const renderPieChart = (data, title) => (
    <PieChart width={300} height={300}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        label
        outerRadius={100}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </PieChart>
  );

  return (
    <Container style={{ padding: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        View Reports
      </Typography>

      {/* User Report Section */}
      <Typography variant="h6" gutterBottom>
        Report per User
      </Typography>
      <FormControl fullWidth margin="normal" sx={{ maxWidth: 300 }}>
        <InputLabel id="select-user-label">Select User</InputLabel>
        <Select
          labelId="select-user-label"
          value={selectedUser || ''}
          onChange={handleUserChange}
          label="Select User"
          sx={{ width: '100%' }}
        >
          {users.map((user) => (
            <MenuItem key={user.idNumber} value={user.idNumber}>
              {user.userName} ({user.role}) ({user.location})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Loading Spinner */}
      {loading && <CircularProgress />}

      {/* Error Alert */}
      {error && (
        <Alert severity="error" style={{ marginTop: '1rem' }}>
          {error}
        </Alert>
      )}

      {/* Data Cards */}
      {!loading && !error && passengerBookingData && consignmentBookingData && expenseData !== null && (
        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          {/* Passenger Booking Data Card */}
          <Grid item xs={12} sm={4}>
            <Card sx={{ backgroundColor: '#ffecb3' }}> {/* Light yellow background for passenger booking */}
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Passenger Booking Data
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Total Reservations: {passengerBookingData.totalReservations}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Total Price: {passengerBookingData.totalPriceByUser}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Mode of Payment:
                </Typography>
                {Object.entries(passengerBookingData.totalPriceByModeOfPayment).map(([paymentMode, price], index) => (
                  <Typography variant="body2" key={index}>
                    {paymentMode}: {price}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>

          {/* Parcel Booking Data Card */}
          <Grid item xs={12} sm={4}>
            <Card sx={{ backgroundColor: '#b3e5fc' }}> {/* Light blue background for parcel booking */}
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Parcel Booking Data
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Total Parcels: {consignmentBookingData.totalParcels}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Total Price: {consignmentBookingData.totalPriceByUser}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Mode of Payment:
                </Typography>
                {Object.entries(consignmentBookingData.totalPriceByModeOfPayment).map(([paymentMode, price], index) => (
                  <Typography variant="body2" key={index}>
                    {paymentMode}: {price}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>

          {/* Expense Data Card */}
          <Grid item xs={12} sm={4}>
            <Card sx={{ backgroundColor: '#c8e6c9' }}> {/* Light green background for expenses */}
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Expenses
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Total Expenses: {expenseData}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {/* Tax Report Section */}
      <Typography variant="h6" gutterBottom>
        Tax Reports
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={fetchTaxReports}
        style={{ marginTop: '1rem' }}
      >
        Fetch Tax Reports
      </Button>

      {loading && <CircularProgress />}

      {error && (
        <Alert severity="error" style={{ marginTop: '1rem' }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (parcelTax !== null || reservationTax !== null) && (
        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={6}>
            <Card sx={{ backgroundColor: '#f28b82' }}> {/* Light red background for Parcel Tax */}
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Parcel Tax
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Amount: {parcelTax || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ backgroundColor: '#a7ffeb' }}> {/* Light green background for Reservation Tax */}
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Reservation Tax
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Amount: {reservationTax || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ViewReports;
