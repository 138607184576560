// src/pages/AboutUs.js

import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';

const AboutUs = () => {
  // Hardcoded team members
  const teamMembers = [
    { name: 'Alice Johnson', role: 'CEO', image: 'https://via.placeholder.com/150' },
    { name: 'Bob Smith', role: 'CTO', image: 'https://via.placeholder.com/150' },
    { name: 'Charlie Brown', role: 'Lead Developer', image: 'https://via.placeholder.com/150' }
  ];

  return (
    <Box sx={{ bgcolor: 'grey.100', py: 5, minHeight: '100vh' }}> {/* Light grey background */}
      <Container sx={{ my: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            About Us
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
            Our Company
          </Typography>
        </Box>
        <Typography paragraph sx={{ textAlign: 'center', maxWidth: 800, mx: 'auto' }}>
          Welcome to DreamCruiser! We are dedicated to providing exceptional travel experiences through our premium bus services. Our goal is to ensure that every journey is comfortable, enjoyable, and stress-free.
        </Typography>
        <Typography paragraph sx={{ textAlign: 'center', maxWidth: 800, mx: 'auto' }}>
          Our team is composed of experienced professionals who are committed to delivering the best possible service. We take pride in our state-of-the-art buses and well-trained drivers who are always ready to assist you.
        </Typography>
        <Typography paragraph sx={{ textAlign: 'center', maxWidth: 800, mx: 'auto' }}>
          Thank you for choosing DreamCruiser. We look forward to serving you and making your travels memorable.
        </Typography>

        <Box sx={{ my: 6, textAlign: 'center' }}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Meet Our Team
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={4} sx={{ p: 3, borderRadius: '16px', textAlign: 'center', bgcolor: 'white' }}>
                  <img
                    src={member.image}
                    alt={member.name}
                    style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover' }}
                  />
                  <Typography variant="h6" sx={{ mt: 2, fontWeight: 'medium' }}>
                    {member.name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
                    {member.role}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;
