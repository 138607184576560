import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axiosInstance from './Admin/axiosInstance';

const TicketDetails = () => {
  const [ticketNumber, setTicketNumber] = useState('');
  const [ticketDetails, setTicketDetails] = useState(null);
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    setTicketNumber(event.target.value);
  };

  const handleCheckTicket = async () => {
    try {
      const response = await axiosInstance.get(`/reservation/viewticket/${ticketNumber}`);
      const data = response.data;

      if (data) {
        setTicketDetails(data);
        setError('');
      } else {
        setTicketDetails(null);
        setError('Ticket not found.');
      }
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      setError('Failed to fetch ticket details. Please try again.');
    }
  };

  const handlePrintReceipt = () => {
    window.print();
  };

  const handleCancelTicket = async () => {
    try {
      const response = await axiosInstance.post(`/reservation/tickets/${ticketNumber}/cancel`);

      if (response.status !== 200) {
        throw new Error(`Error: ${response.statusText}`);
      }

      alert('Ticket cancelled successfully');
      setTicketDetails(null);
      setTicketNumber('');
    } catch (error) {
      console.error('Error cancelling ticket:', error);
      alert('Failed to cancel ticket. Please try again.');
    }
  };

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Typography variant="h4" gutterBottom>
        Check Ticket Details
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <TextField
          label="Ticket Number"
          variant="outlined"
          size="small"
          value={ticketNumber}
          onChange={handleInputChange}
          style={{ marginRight: '1rem' }}
        />
        <Button variant="contained" color="primary" onClick={handleCheckTicket}>
          Check Ticket
        </Button>
      </div>
      {error && (
        <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
      {ticketDetails && (
        <TableContainer component={Paper} style={{ marginTop: '2rem', width: '80%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(ticketDetails).map(([key, value], index) => (
                <TableRow key={key} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff' }}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <Button variant="contained" color="primary" onClick={handlePrintReceipt}>
              Print Receipt
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCancelTicket}>
              Cancel Ticket
            </Button>
          </div>
        </TableContainer>
      )}
    </Container>
  );
};

export default TicketDetails;
