import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem } from '@mui/material';

const ParcelListDialog = ({ open, onClose, onConfirm }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [direction, setDirection] = useState('');

  const handleConfirm = () => {
    if (startDate && endDate && direction) {
      onConfirm(startDate, endDate, direction);
    } else {
      alert('Please fill in all fields');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Date Range and Route</DialogTitle>
      <DialogContent>
        <TextField
          label="Start Date"
          type="date"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <TextField
          select
          label="Route"
          fullWidth
          margin="normal"
          value={direction}
          onChange={(e) => setDirection(e.target.value)}
        >
          <MenuItem value="1">Nairobi-Wajir</MenuItem>
          <MenuItem value="2">Wajir-Nairobi</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleConfirm} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParcelListDialog;
