import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import {
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { AddCircle, Edit, Delete } from '@mui/icons-material';

const roles = ['ADMIN', 'CASHIER', 'AGENT', 'CONDUCTOR', 'DRIVER'];

const AddUserForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    phoneNumber: '',
    idNumber: '',
    email: '',
    role: '',
    password: '',
    location: '', 
    employmentStatus: '',
  });
  const [users, setUsers] = useState([]);
  const statusOptions = ['staff', 'contractor'];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/viewallusers');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const locations = [
    'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
    'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
    'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axiosInstance.put(`/users/updateuser/${selectedUserId}`, formData);
      } else {
        await axiosInstance.post('/register', formData);
      }
      fetchUsers(); // Refresh the user list
      handleCancelClick(); // Reset form and close it
    } catch (error) {
      console.error('Error submitting user:', error);
    }
  };

  const handleAddUserClick = () => {
    setShowForm(true);
    setEditMode(false);
    setFormData({
      firstName: '',
      lastName: '',
      userName: '',
      phoneNumber: '',
      idNumber: '',
      email: '',
      role: '',
      password: '',
      location: '', 
      employmentStatus: '',
    });
  };

  const handleCancelClick = () => {
    setShowForm(false);
    setFormData({
      firstName: '',
      lastName: '',
      userName: '',
      phoneNumber: '',
      idNumber: '',
      email: '',
      role: '',
      password: '',
      location: '', 
      employmentStatus: '',// Add location field
    });
    setEditMode(false);
    setSelectedUserId(null);
  };

  const handleEditClick = (user) => {
    setShowForm(true);
    setEditMode(true);
    setSelectedUserId(user.idNumber);
    setFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
      phoneNumber: user.phoneNumber,
      idNumber: user.idNumber,
      email: user.email,
      role: user.role,
      password: '', // Optional: Keep it empty for security reasons
      location: user.location,
      employmentStatus: user.employmentStatus, // Add location field
    });
  };

  // const handleDeleteClick = async (idNumber) => {
  //   try {
  //     await axiosInstance.delete(`/users/delete/${idNumber}`);
  //     fetchUsers(); // Refresh the user list
  //   } catch (error) {
  //     console.error('Error deleting user:', error);
  //   }
  // };

  const handleToggleStatusClick = async (user) => {
    const updatedStatus = !user.status;  // Toggle the status (null will be treated as false)
  
    try {
      await axiosInstance.put(`/users/${user.idNumber}/${updatedStatus}`);
      
      // Optimistically update the local user state
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.idNumber === user.idNumber ? { ...u, status: updatedStatus } : u
        )
      );
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">{showForm ? (editMode ? 'Edit User' : 'Add New User') : 'User List'}</Typography>
        {!showForm && (
          <Button variant="contained" color="primary" startIcon={<AddCircle />} onClick={handleAddUserClick}>
            Add User
          </Button>
        )}
      </Box>
      {showForm ? (
        <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: 2 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Second Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="UserName"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="ID/Passport Number"
                  name="idNumber"
                  value={formData.idNumber}
                  onChange={handleChange}
                  required
                  disabled={editMode} // Disable editing of ID/Passport number
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email (optional)"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>User Role</InputLabel>
                  <Select name="role" value={formData.role} onChange={handleChange} required>
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
              <InputLabel id="location-label">Location</InputLabel>
                <Select
                    labelId="location-label"
                    id="location"
                    label="Location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                >
                    {locations.map((location) => (
                        <MenuItem key={location} value={location}>
                            {location}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Employment Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="employmentStatus"
                    label="employmentStatus"
                    name="employmentStatus"
                    value={formData.employmentStatus}
                    onChange={handleChange}
                  >
                    {statusOptions.map((employmentStatus) => (
                      <MenuItem key={employmentStatus} value={employmentStatus}>
                        {employmentStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required={!editMode} // Password is required only for new users
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="submit" variant="contained" color="primary">
                  {editMode ? 'Update User' : 'Add User'}
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      ) : (
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
            <TableRow sx={{ backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#e0f7fa' } }}>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>First Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Second Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Username</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Phone Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>ID/Passport Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>User Role</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Location</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow
                  key={user.idNumber}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#fff',
                    '&:hover': { backgroundColor: '#e0f7fa' }, // Hover effect
                  }}
                >
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.userName}</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                  <TableCell>{user.idNumber}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{user.location}</TableCell>
                  <TableCell>{user.status ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell>
                    <Button
                      startIcon={<Edit />}
                      color="primary"
                      variant="outlined"
                      onClick={() => handleEditClick(user)}
                      sx={{ mr: 1 }}
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={user.status ? <Delete /> : <AddCircle />}
                      color={user.status ? 'secondary' : 'success'}
                      variant="outlined"
                      onClick={() => handleToggleStatusClick(user)}
                    >
                      {user.status ? 'Deactivate' : 'Activate'}
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AddUserForm;
