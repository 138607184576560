import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // 'http://localhost:8088',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
});

// Interceptor to add token to each request n 
axiosInstance.interceptors.request.use(
  config => {
    // Retrieve the token from local storage or any other place you store it
    const token = localStorage.getItem('token');
    console.log('Request Headers for access:', config.headers);
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
