import React, { useState } from 'react';
import axiosInstance from '../Admin/axiosInstance'; // Make sure this imports the Axios instance correctly
import { TextField, Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ParcelStatus = () => {
  const [trackingCode, setTrackingCode] = useState('');
  const [parcelDetails, setParcelDetails] = useState(null);
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    setTrackingCode(event.target.value);
  };

  const handleCheckParcel = async () => {
    try {
      const response = await axiosInstance.get(`/parcel/status/${trackingCode}`); // Correct method
      setParcelDetails(response.data); // Use response.data
      setError('');
    } catch (error) {
      console.error('Error fetching parcel details:', error);
      setError('Failed to fetch parcel details. Please try again.');
    }
  };

  const handlePrintReceipt = () => {
    window.print();
  };

  const handleCancelParcel = async () => {
    try {
      await axiosInstance.post(`/status/${trackingCode}/cancel`); // Correct method
      alert('Parcel cancelled successfully');
      setParcelDetails(null);
      setTrackingCode('');
    } catch (error) {
      console.error('Error cancelling parcel:', error);
      alert('Failed to cancel parcel. Please try again.');
    }
  };

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Typography variant="h4" gutterBottom>
        Check Parcel Details
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <TextField
          label="Tracking Code"
          variant="outlined"
          size="small"
          value={trackingCode}
          onChange={handleInputChange}
          style={{ marginRight: '1rem' }}
        />
        <Button variant="contained" color="primary" onClick={handleCheckParcel}>
          Check Parcel
        </Button>
      </div>
      {error && (
        <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
      {parcelDetails && (
        <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(parcelDetails).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <Button variant="contained" color="primary" onClick={handlePrintReceipt}>
              Print Receipt
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCancelParcel}>
              Cancel Parcel
            </Button>
          </div> */}
        </TableContainer>
      )}
    </Container>
  );
};

export default ParcelStatus;
