import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, Grid, Alert, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './Admin/axiosInstance'; // Ensure this is correctly configured
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Define a basic theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Replace with your desired primary color
    },
    secondary: {
      main: '#dc004e', // Replace with your desired secondary color
    },
    default: {
      main: '#9e9e9e', // Replace with your desired default color
    },
  },
});

const BookingForm = ({ bus, seat }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;
  const direction = state?.direction || '';
  const busRegistration = state?.busRegistration || '';

  // Get current date and time
  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toISOString().split('T')[1].split('.')[0];

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    idNumber: '',
    phone: '',
    email: '',
    nationality: '',
    modeOfPayment: '',
    payStatus: false,
    mpesaNumber: '',
    pickUpLocation: '',
    discount: 0, 
    gender: '', 
    hasLuggage: false, 
    luggageDescription: '',
    luggageAmount: 0,
  });

  const [voucherNumber, setVoucherNumber] = useState(''); // Add state for voucher number

  const [ticketDetails, setTicketDetails] = useState({
    destination: state?.destination || bus?.destination || '',
    source: state?.source || bus?.source || '',
    travelDate: state?.bookDate || currentDate,
    scheduleTime: state?.bookTime || currentTime,
    seatNumber: seat || state?.seats || '',
    price: state?.price || bus?.cost || 0,
    scheduleDate: state?.travelDate || bus?.travelDate || currentDate, // Ensure default value is provided
    direction: state?.direction || bus?.direction || 0, // Ensure default value is provided
    ticketNumber: `DCL${Math.floor(10000 + Math.random() * 90000)}`,
    busImage: '../images/Home_page_banner.png'
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (seat) {
      setTicketDetails(prevDetails => ({
        ...prevDetails,
        seatNumber: seat
      }));
    }
  }, [seat]);

  const validateForm = () => {
    const { phone, idNumber, modeOfPayment, mpesaNumber } = form;
    const phoneRegex = /^0\d{9}$/;
    const idNumberLength = idNumber.length;

    if (!phoneRegex.test(phone)) {
      setErrorMessage('Phone number must start with 0 and be exactly 10 digits long.');
      return false;
    }
    
    if (idNumberLength < 7 || idNumberLength > 9) {
      setErrorMessage('ID number must be between 7 and 9 digits long.');
      return false;
    }

    if (modeOfPayment === 'Mpesa' && !mpesaNumber) {
      setErrorMessage('MPesa number is required when payment mode is Mpesa.');
      return false;
    }

    if (modeOfPayment === 'Voucher' && !voucherNumber) {
      setErrorMessage('Voucher number is required when payment mode is Voucher.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm(prevForm => ({
      ...prevForm,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (name === 'discount') {
      const discountValue = parseFloat(value || 0);
      setForm(prevForm => ({
        ...prevForm,
        discount: discountValue
      }));
      setTicketDetails(prevDetails => ({
        ...prevDetails,
        price: (state?.price || bus?.cost || 0) - discountValue >= 0
          ? (state?.price || bus?.cost || 0) - discountValue
          : 0,
      }));
    }
    
    if (name === 'hasLuggage') {
      setForm(prevForm => ({
        ...prevForm,
        hasLuggage: checked,
        luggageAmount: checked ? form.luggageAmount : 0,
        luggageDescription: checked ? form.luggageDescription : ''
      }));
    }
    
    if (name === 'luggageAmount') {
      setForm(prevForm => ({
        ...prevForm,
        luggageAmount: parseFloat(value || 0)
      }));
    }
    
    if (name === 'luggageDescription') {
      setForm(prevForm => ({
        ...prevForm,
        luggageDescription: value
      }));
    }
  };

  const handleVoucherChange = (e) => {
    setVoucherNumber(e.target.value);
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
  
    if (!validateForm()) return;
    // If the mode of payment is FOC, set price to 0
     const basePrice = form.modeOfPayment === 'Foc' ? 0 : ticketDetails.price;
     const finalPrice = form.hasLuggage ? basePrice + form.luggageAmount : basePrice;
  
    const data = { 
      ...form, 
      ...ticketDetails, 
      bookDate: currentDate, 
      bookingDateTime: new Date().toISOString(), 
      bookTime: currentTime, 
      phone: parseInt(form.phone, 10),
      price: finalPrice,
      discount: form.discount 
    };

    let endpoint = '';

    if (form.modeOfPayment === 'Voucher') {
      endpoint = `/reservation/add/${voucherNumber}/1`;
    } else {
      endpoint = type === 'book' ? `/reservation/addBook/${direction}` : `/reservation/addReserve/${direction}`;
    }

    try {
      const response = await axiosInstance.post(endpoint, data);

      // Handle success
        if (type === 'book') {
          // Navigate to the receipt page if it's a booking
          setSuccessMessage('Booking successfully submitted!');
          setTimeout(() => {
          navigate('/ticket', { state: data });
          }, 2000); 
        } else {
          // Show a success message if it's a reservation
          setSuccessMessage('Ticket has been reserved successfully!');
          // Delay navigation to give time to show the success message
         setTimeout(() => {
          navigate('/home');
         }, 2000); 
        }
        setErrorMessage('');
  
      // setSuccessMessage('Booking successfully submitted!');
      // setErrorMessage('');
      // navigate('/ticket', { state: data });
    } catch (error) {
      console.error('Error submitting booking:', error);
      setErrorMessage(error.response?.data?.message || error.message);
      setSuccessMessage('');
    }
  };

  const handleClear = () => {
    setForm({
      firstName: '',
      lastName: '',
      idNumber: '',
      phone: '',
      email: '',
      nationality: '',
      modeOfPayment: '',
      payStatus: false,
      mpesaNumber: '',
      pickUpLocation: '',
      hasLuggage: '',
      luggageDescription: '',
      luggageAmount: 0,
      gender: '',
      discount: 0 
    });
    setTicketDetails({
      destination: state?.destination || bus?.destination || '',
      source: state?.source || bus?.source || '',
      travelDate: state?.bookDate || currentDate,
      scheduleTime: state?.bookTime || currentTime,
      seatNumber: seat || state?.seats || '',
      price: state?.price || bus?.cost || 0,
      scheduleDate: state?.travelDate || bus?.travelDate || currentDate, // Ensure default value is provided
      direction: state?.direction || bus?.direction || 0, // Ensure default value is provided
      ticketNumber: `DCL${Math.floor(10000 + Math.random() * 90000)}`,
      busImage: '../images/Home_page_banner.png'
    });
    setVoucherNumber('');
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          minHeight: '100vh', 
          padding: 2 
        }}
      >
        <Box 
          sx={{ 
            width: '100%', 
            maxWidth: 600 
          }}
        >
          <Typography variant="h4" gutterBottom>
            Booking Submission
          </Typography>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="First Name"
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  required
                  sx={{ maxWidth: 300 }} // Reduced width
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Last Name"
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  required
                  sx={{ maxWidth: 300 }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="ID Number/Passport"
                  name="idNumber"
                  value={form.idNumber}
                  onChange={handleChange}
                  required
                  type="number"
                  sx={{ maxWidth: 300 }} // Reduced width
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Phone"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                  required
                  type="tel"
                  sx={{ maxWidth: 300 }} // Reduced width
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  type="email"
                  sx={{ maxWidth: 300 }} // Reduced width
                />
              </Grid>
              {/* <Grid container spacing={2}> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Nationality"
                    name="nationality"
                    value={form.nationality}
                    onChange={handleChange}
                    required
                    sx={{ maxWidth: 300 }} // Reduced width
                  />
                </Grid>
                {/* </Grid> */}
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hasLuggage"
                        checked={form.hasLuggage}
                        onChange={handleChange}
                      />
                    }
                    label="Has Luggage"
                  />
                </Grid>
                {form.hasLuggage && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Luggage Description"
                        name="luggageDescription"
                        value={form.luggageDescription}
                        onChange={handleChange}
                        sx={{ maxWidth: 300 }} // Reduced width
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Amount for Luggage"
                        name="luggageAmount"
                        value={form.luggageAmount}
                        onChange={handleChange}
                        type="number"
                        sx={{ maxWidth: 300 }} // Reduced width
                      />
                    </Grid>
                  </>
                )}

               <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="gender"
                      value={form.gender}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal" sx={{ maxWidth: 300 }}>
                    <InputLabel>Mode of Payment</InputLabel>
                    <Select
                      name="modeOfPayment"
                      value={form.modeOfPayment}
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="Mpesa">Mpesa</MenuItem>
                      <MenuItem value="Mcash">Mpesa and Cash</MenuItem>
                      <MenuItem value="Foc">FOC</MenuItem>
                      <MenuItem value="Voucher">Voucher</MenuItem>
                      <MenuItem value="AirtelMoney">Airtel Money</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              


              {form.modeOfPayment === 'Voucher' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Voucher Number"
                    name="voucherNumber"
                    value={voucherNumber}
                    onChange={handleVoucherChange}
                    required
                    sx={{ maxWidth: 300 }}
                  />
                </Grid>
              )}

              {form.modeOfPayment === 'Mpesa' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Mpesa Number"
                    name="mpesaNumber"
                    value={form.mpesaNumber}
                    onChange={handleChange}
                    required={form.modeOfPayment === 'Mpesa'}
                    type="tel"
                    sx={{ maxWidth: 300 }} // Reduced width
                  />
                </Grid>
              )}

             {form.modeOfPayment === 'AirtelMoney' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Airtel Number"
                    name="airtelNumber"
                    value={form.mpesaNumber}
                    onChange={handleChange}
                    required={form.modeOfPayment === 'AirtelMoney'}
                    type="tel"
                    sx={{ maxWidth: 300 }} // Reduced width
                  />
                </Grid>
              )}


              {/* // New condition for Mpesa and Cash */}
              {form.modeOfPayment === 'Mcash' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Amount to Pay via Mpesa"
                      name="mpesaAmount"
                      value={form.mpesaAmount}
                      onChange={handleChange}
                      required
                      type="number"
                      sx={{ maxWidth: 300 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Mpesa Number"
                      name="mpesaNumber"
                      value={form.mpesaNumber}
                      onChange={handleChange}
                      required
                      type="tel"
                      sx={{ maxWidth: 300 }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Pick-Up Location"
                  name="pickUpLocation"
                  value={form.pickUpLocation}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Discount"
                  name="discount"
                  value={form.discount}
                  onChange={handleChange}
                  type="number"
                  sx={{ maxWidth: 300 }}
                />
              </Grid>
              
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleSubmit(e, 'book')}
              >
                Book
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => handleSubmit(e, 'reserve')}
              >
                Reserve
              </Button>
              <Button
                variant="outlined"
                color="default"
                onClick={handleClear}
              >
                Clear
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

BookingForm.propTypes = {
  bus: PropTypes.object,
  seat: PropTypes.string
};

export default BookingForm;