import React, { useEffect, useState } from 'react';
// import {
//   Container, Typography, Box, Grid, CircularProgress, TextField, Button, Dialog, DialogActions,
//   DialogContent, DialogContentText, DialogTitle, MenuItem, Select
// } from '@mui/material';
import {
  Container,  DialogContent, Box, Grid, Typography, Button, CircularProgress, Dialog, DialogTitle, DialogContentText, 
  DialogActions, Table, TableBody, TableCell, Select, MenuItem, TextField, TableContainer, TableHead, TableRow, Paper, TablePagination,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import axiosInstance from './axiosInstance';
import 'chart.js/auto';


// const Reports = ({ userId, username, role }) => {
const Reports = () => {

  // Retrieve values from localStorage
  const userId = localStorage.getItem('userId');
  const username = localStorage.getItem('username');
  const role = localStorage.getItem('role');

  const [passengerBookingData, setPassengerBookingData] = useState(null);
  const [consignmentBookingData, setConsignmentBookingData] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [cashforwardData, setCashforwardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  const [open, setOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null); 
  const [openModal, setOpenModal] = useState(false); 
  const [page, setPage] = useState(0); // For pagination
  const [rowsPerPage, setRowsPerPage] = useState(5); // For pagination
  const [cashForwardData, setCashForwardData] = useState({
    mpesa: 0,
    cash: 0,
    voucher: 0,
    totalAmount: 0,
    receiverName: '',
  });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResponse = await axiosInstance.get('/users/viewallusers');
        setUsers(usersResponse.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const passengerResponse = await axiosInstance.get(`/reservation/statistics/${userId}/${selectedDate}`);
        setPassengerBookingData(passengerResponse.data);
        
        const consignmentResponse = await axiosInstance.get(`/parcel/statistics/${userId}/${selectedDate}`);
        setConsignmentBookingData(consignmentResponse.data);

        const expenseResponse = await axiosInstance.get(`/expense/statistics/${selectedDate}/${userId}`);
        setExpenseData(expenseResponse.data);

        const cashforwardResponse = await axiosInstance.get(`/cashforward/receiver/${username}/${selectedDate}`);
        setCashforwardData(cashforwardResponse.data);
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, username, selectedDate]);

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
        <CircularProgress />
        <Typography variant="h6" mt={2}>Fetching report data...</Typography>
      </Box>
    );
  }
  
  // Handle status change
  const handleStatusChange = async (id, newStatus) => {
    try {
      await axiosInstance.put(`/cashforward/status/${id}/${newStatus}`);
      setCashforwardData((prevData) =>
        prevData.map((entry) =>
          entry.id === id ? { ...entry, cashForwardStatus: newStatus } : entry
        )
      );
      alert(`Cash forward status updated to: ${newStatus}`);
    } catch (error) {
      console.error('Error updating status', error);
      alert('Failed to update status');
    }
  };

  // Open modal when a row is clicked
  const handleRowClick = (entry) => {
    setSelectedEntry(entry);
    setOpenModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const passengerBookingChartData = {
    labels: ['Mpesa', 'Cash', 'Voucher'],
    datasets: [
      {
        data: [
          passengerBookingData?.totalPriceByModeOfPayment?.Mpesa || 0,
          passengerBookingData?.totalPriceByModeOfPayment?.Cash || 0,
          passengerBookingData?.totalPriceByModeOfPayment?.Voucher || 0,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const consignmentBookingChartData = {
    labels: ['Mpesa', 'Cash'],
    datasets: [
      {
        data: [
          consignmentBookingData?.totalPriceByModeOfPayment?.Mpesa || 0,
          consignmentBookingData?.totalPriceByModeOfPayment?.Cash || 0,
          consignmentBookingData?.totalPriceByModeOfPayment?.Voucher || 0,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const totalCashforward = {
    mpesaTotal: cashforwardData.reduce((acc, curr) => acc + curr.mpesa, 0),
    cashTotal: cashforwardData.reduce((acc, curr) => acc + curr.cash, 0),
    voucherTotal: cashforwardData.reduce((acc, curr) => acc + curr.voucher, 0),
    totalAmount: cashforwardData.reduce((acc, curr) => acc + curr.totalAmount, 0),
  };

  const handleClickOpen = () => {
    setCashForwardData({
      mpesa: totalCashforward.mpesaTotal,
      cash: totalCashforward.cashTotal,
      voucher: totalCashforward.voucherTotal,
      totalAmount: totalCashforward.totalAmount,
      receiverName: ''
    });
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleCashForwardSubmit = async () => {
    try {
      await axiosInstance.post('/cashforward/add', cashForwardData);
      alert('Cash forward successfully submitted!');
      handleClose();
    } catch (error) {
      console.error('Error forwarding cash:', error);
      alert('Failed to forward cash.');
    }
  };

  // Filter users to only include those with role 'Admin'
  const adminUsers = users.filter(user => user.role === 'ADMIN');
  
  return (
    <Container style={{ padding: '2rem' }}>
      <Box mb={4}>
        {/* <Typography variant="h4" gutterBottom>
          Report for {username}
        </Typography> */}
        <Typography variant="h6" gutterBottom>
          Date: {selectedDate}
        </Typography>
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>

      <Grid container spacing={3}>
        {/* Passenger Booking Pie Chart */}
        <Grid item xs={12} sm={6} md={4}>
          <Box border={2} borderColor="green" borderRadius={2} padding={2}>
            <Typography variant="h6" gutterBottom>
              Passenger Booking
            </Typography>
            <Pie data={passengerBookingChartData} />
          </Box>
        </Grid>

        {/* Consignment Booking Pie Chart */}
        <Grid item xs={12} sm={6} md={4}>
          <Box border={2} borderColor="orange" borderRadius={2} padding={2}>
            <Typography variant="h6" gutterBottom>
              Consignment Booking
            </Typography>
            <Pie data={consignmentBookingChartData} />
          </Box>
        </Grid>

        {/* Expense Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Box border={2} borderColor="red" borderRadius={2} padding={2} textAlign="center">
            <Typography variant="h6" gutterBottom>
              Expenses
            </Typography>
            <CircularProgress variant="determinate" value={expenseData || 0} size={80} thickness={5} />
            <Typography variant="body1" gutterBottom>
              {expenseData || 0} KES
            </Typography>
          </Box>
        </Grid>

        {/* Cashforward Data Table */}
        <Grid item xs={12}>
          <Box border={2} borderColor="blue" borderRadius={2} padding={2}>
        <Typography variant="h6" gutterBottom>
          Cashforward Data
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
            <TableRow>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Date</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Sender Name</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Mpesa</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Cash</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Voucher</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Total Amount</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Status</TableCell>
                <TableCell style={{ backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold' }}>Actions</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {cashforwardData.map((entry) => (
                <TableRow key={entry.id} onClick={() => handleRowClick(entry)} style={{ cursor: 'pointer' }}>
                  <TableCell>{entry.date}</TableCell>
                  <TableCell>{entry.senderName}</TableCell>
                  <TableCell>{entry.mpesa} KES</TableCell>
                  <TableCell>{entry.cash} KES</TableCell>
                  <TableCell>{entry.voucher} KES</TableCell>
                  <TableCell>{entry.totalAmount} KES</TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: entry.cashForwardStatus === 'PENDING' ? 'orange' : entry.cashForwardStatus === 'APPROVE' ? 'green' : 'red',
                      }}
                    >
                      {entry.cashForwardStatus}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {entry.cashForwardStatus === 'PENDING' ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents row click when button clicked
                            handleStatusChange(entry.id, 'APPROVE');
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStatusChange(entry.id, 'REJECT');
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          Reject
                        </Button>
                      </>
                    ) : (
                      <Typography variant="body2">
                        {entry.cashForwardStatus === 'APPROVE' ? 'Approved' : 'Rejected'}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
           {/* Pagination */}
           <TablePagination
            component="div"
            count={cashforwardData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

          {/* Modal for row details */}
      <Dialog open={openModal} 
        onClose={handleCloseModal}
        maxWidth="md" // Set the maximum width of the dialog (can be 'xs', 'sm', 'md', 'lg', 'xl')
        fullWidth>
        <DialogTitle>Cashforward Details</DialogTitle>
        <DialogContent>
          {selectedEntry && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#e0f7fa', fontWeight: 'bold' }}>Field</TableCell>
                  <TableCell style={{ backgroundColor: '#e0f7fa', fontWeight: 'bold' }}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>{selectedEntry.date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sender Name</TableCell>
                  <TableCell>{selectedEntry.senderName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Mpesa</TableCell>
                  <TableCell>{selectedEntry.mpesa} KES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cash</TableCell>
                  <TableCell>{selectedEntry.cash} KES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Voucher</TableCell>
                  <TableCell>{selectedEntry.voucher} KES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>{selectedEntry.totalAmount} KES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: selectedEntry.cashForwardStatus === 'PENDING' ? 'orange' : selectedEntry.cashForwardStatus === 'APPROVE' ? 'green' : 'red',
                      }}
                    >
                      {selectedEntry.cashForwardStatus}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
       </Grid>
       </Grid>

      <Box mb={4}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Forward Cash
      </Button>
    </Box>

    {/* Dialog for cash forward */}
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Forward Cash</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Forward the total money based on each category. Only the cash amount is editable.
        </DialogContentText>
        <TextField
          margin="dense"
          label="Mpesa"
          fullWidth
          value={cashForwardData.mpesa}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Cash"
          fullWidth
          value={cashForwardData.cash}
          onChange={(e) => setCashForwardData({ ...cashForwardData, cash: parseFloat(e.target.value) || 0 })}
        />
        <TextField
          margin="dense"
          label="Voucher"
          fullWidth
          value={cashForwardData.voucher}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          label="Total Amount"
          fullWidth
          value={cashForwardData.mpesa + cashForwardData.cash + cashForwardData.voucher}
          InputProps={{
            readOnly: true,
          }}
        />
         <Select
          label="Receiver Name"
          fullWidth
          value={cashForwardData.receiverName}
          onChange={(e) => setCashForwardData({ ...cashForwardData, receiverName: e.target.value })}
        >
          {adminUsers.map(user => (
              <MenuItem key={user.id} value={user.username}>
                {user.username}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button onClick={handleCashForwardSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
    </Container>
  );
};

export default Reports;
