import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Button, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance';
import './../../App.css'; 

const SeatTransfer = ({ onSeatSelect }) => {
  const location = useLocation();
  const busDetails = location.state || {};
//   const busDetails = location.state?.busDetails || {};

  console.log('Bus Details:', busDetails); 

  const [selectedSeats, setSelectedSeats] = useState([]);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [travelDate, settravelDate] = useState('');
  const [loadingSeats, setLoadingSeats] = useState(false);
  const navigate = useNavigate();

  const { direction = '', source = '', destination = '', cost = 0, departure = '', reservationId = '' } = busDetails;

  const fetchSeats = () => {
    if (travelDate && direction) {
      setLoadingSeats(true);
      axiosInstance.get(`/reservation/bookedseats/${travelDate}/${direction}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            setBookedSeats(response.data);
          } else {
            console.error('Unexpected response format for booked seats:', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching booked seats:', error);
        });

      axiosInstance.get(`/reservation/bookedReserves/${travelDate}/${direction}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            setReservedSeats(response.data);
          } else {
            console.error('Unexpected response format for reserved seats:', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching reserved seats:', error);
        })
        .finally(() => {
          setLoadingSeats(false);
        });
    } else {
      console.error('Missing travelDate or direction:', { travelDate, direction });
    }
  };

  useEffect(() => {
    if (travelDate) {
      fetchSeats();
    }
  }, [travelDate, direction]);

  const handleSeatClick = (seat) => {
    if (!bookedSeats.includes(seat) && !reservedSeats.includes(seat)) {
      setSelectedSeats(prevSeats => 
        prevSeats.includes(seat) ? prevSeats.filter(s => s !== seat) : [...prevSeats, seat]
      );
      if (onSeatSelect) {
        onSeatSelect(seat);
      }
    }
  };

  const handleTransfer = () => {
    if (!reservationId || !travelDate || selectedSeats.length === 0) {
      console.error('Reservation ID, date, or selected seats are missing');
      return;
    }

    const transferPromises = selectedSeats.map(seat => {
      return axiosInstance.put(`/reservation/edit/${reservationId}`, {
        seatNumber: seat,
        travelDate: travelDate
      });
    });

    Promise.all(transferPromises)
      .then(() => {
        alert(`Your seat(s) ${selectedSeats.join(', ')} has been transferred to ${travelDate}`);
        navigate('/home');
      })
      .catch(error => {
        console.error("Error transferring seats:", error);
      });
  };

  const handleLock = () => {
    if (!reservationId || !travelDate || selectedSeats.length === 0) {
      console.error('Reservation ID, date, or selected seats are missing');
      return;
    }

    const lockPromises = selectedSeats.map(seat => {
      return axiosInstance.put(`/reservation/editlock/${reservationId}`, {
        seatNumber: seat,
        travelDate: travelDate
      });
    });

    Promise.all(lockPromises)
      .then(() => {
        alert(`Your seat(s) ${selectedSeats.join(', ')} has been transferred and locked for ${travelDate}`);
        navigate('/home');
      })
      .catch(error => {
        console.error("Error locking seats:", error);
      });
  };

  const seats = [
    ['1D', '2', '', '3', '4D'],
    ['5D', '6', '', '7', '8D'],
    ['9D', '10', '', '11', '12D'],
    ['13D', '14', '', '15', '16D'],
    ['17D', '18', '', '19', '20D'],
    ['21D', '22', '', '23', '24D'],
    ['25D', '26', '', '27', '28D'],
    ['29D', '30', '', '31', '32D'],
    ['33D', '34', '', '35', '36D'],
    ['37D', '38', '', '39', '40D'],
    ['41D', '42', '', '43', '44D'],
    ['45D', '46', '47', '48', '49D']
  ];


  const renderSeatRow = (seats, isLastRow = false) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
      {seats.map((seat, index) => (
        <Button
          key={index}
          onClick={() => seat && handleSeatClick(seat)}
          disabled={seat === '' || bookedSeats.includes(seat) || reservedSeats.includes(seat)}
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 2px',
            borderRadius: '4px',
            backgroundColor: seat === '' 
              ? '#ffffff' 
              : selectedSeats.includes(seat) 
              ? 'green' 
              : bookedSeats.includes(seat) 
              ? 'red' 
              : reservedSeats.includes(seat) 
              ? '#707793' 
              : '#dbbab6',
            '&:hover': {
              backgroundColor: seat === '' 
                ? '#ffffff' 
                : (!bookedSeats.includes(seat) && !reservedSeats.includes(seat) && !selectedSeats.includes(seat) 
                ? '#e0e0e0' 
                : undefined),
            },
            color: seat === '' ? '#ffffff' : '#000',
            fontSize: '0.75rem',
          }}
        >
          {seat && <Typography variant="body2">{seat}</Typography>}
        </Button>
      ))}
      {!isLastRow && <Box sx={{ width: 3 }}></Box>}
    </Box>
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <TextField
          label="New Date"
          type="date"
          value={travelDate}
          onChange={(e) => settravelDate(e.target.value)}
          sx={{ mb: 2, width: '50%', maxWidth: '300px' }} // Adjusting the width here
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography variant="h6" gutterBottom>
          From: {source} To: {destination}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Select Your Seat
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: 'green',
                color: '#fff',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
              }}
            >
              <Typography variant="body2">S</Typography>
            </Button>
            <Typography variant="body1">Selected</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: 'red',
                color: '#fff',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              <Typography variant="body2">B</Typography>
            </Button>
            <Typography variant="body1">Booked</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: '#707793',
                color: '#fff',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'darkorange',
                },
              }}
            >
              <Typography variant="body2">R</Typography>
            </Button>
            <Typography variant="body1">Reserved</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{
                width: 30,
                height: 30,
                backgroundColor: '#dbbab6',
                color: '#000',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
            >
              <Typography variant="body2">U</Typography>
            </Button>
            <Typography variant="body1">Unbooked</Typography>
          </Grid>
          </Grid>
        </Box>
      </Box>
      {loadingSeats ? (
        <Typography>Loading seats...</Typography>
      ) : (
        <>
          {seats.map((row, index) => renderSeatRow(row, index === seats.length - 1))}
        </>
      )}
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTransfer} // Updated
          sx={{ mr: 2 }}
        >
          Transfer Seats
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLock} // Updated
        >
          Transfer and Lock
        </Button>
      </Box>
    </Box>
  );
};

SeatTransfer.propTypes = {
  onSeatSelect: PropTypes.func.isRequired,
};

export default SeatTransfer;
