import React, { useState } from 'react';
import { Typography, Button, Container, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SearchForm from './SearchForm';
import Modal from './Modal';
import bannerImage from '../images/Home_page_banner.png';
import step1Image from '../images/hbc-1.png';
import step2Image from '../images/hbc-2.png';
import step3Image from '../images/hbc-3.png';

const LandingPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [noBusesMessage, setNoBusesMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleBookNow = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNoBusesMessage('');
  };

  const handleSearch = async (searchData) => {
    console.log('Search data:', searchData);
    const { source, destination, date } = searchData;
    const baseUrl = process.env.REACT_APP_BASE_URL;
  

    try {
      // const response = await fetch(`http://localhost:8088/schedule/viewallschedules`, {
      const response = await fetch(`http://localhost:8088/schedule/all/${source}/${destination}/${date}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      if (result.length === 0) {
        setNoBusesMessage(`No available buses from ${source} to ${destination} on ${date}`);
      } else {
        // Store the bus data in local storage or a state management solution
        localStorage.setItem('buses', JSON.stringify(result));
        navigate('/buses'); // Navigate to the BusList page
        setShowModal(false); // Close the modal
      }
    } catch (error) {
      console.error('Error fetching schedule data:', error);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          color: 'white',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container>
          <Typography variant="h3" gutterBottom>
            Welcome to Dream Cruiser
          </Typography>
          <Typography variant="h5" gutterBottom>
            Need a comfortable travel?
          </Typography>
          <Button variant="contained" color="primary" onClick={handleBookNow}>
            Book Now
          </Button>
        </Container>
      </div>

      <Modal show={showModal} handleClose={handleCloseModal}>
        <SearchForm onSearch={handleSearch} />
        {noBusesMessage && (
          <Typography variant="body1" color="error" style={{ marginTop: '1rem', textAlign: 'center' }}>
            {noBusesMessage}
          </Typography>
        )}
      </Modal>

      <Container style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Get Your Tickets With Just 3 Steps
        </Typography>
        <Typography variant="body1" gutterBottom>
          Have a look at our popular reasons why you should choose DreamCruiser Bus.
          Just book a bus and get a ticket for your great journey!
        </Typography>
      </Container>

      <Container style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardMedia
                component="div"
                sx={{ height: 140, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <img src={step1Image} alt="Search Your Bus" style={{ height: '80px', width: 'auto' }} />
              </CardMedia>
              <CardContent>
                <Typography variant="h6">Search Your Bus</Typography>
                <Typography variant="body2" color="textSecondary">
                  Choose your Pickup, Destination, journey dates and search for the lowest price bus ticket around India to make your travel budget-friendly.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardMedia
                component="div"
                sx={{ height: 140, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <img src={step2Image} alt="Choose The Ticket" style={{ height: '80px', width: 'auto' }} />
              </CardMedia>
              <CardContent>
                <Typography variant="h6">Choose The Ticket</Typography>
                <Typography variant="body2" color="textSecondary">
                  Smart filters, a range of bus schedules, and fleet and amenities to make your travel journey comfortable.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardMedia
                component="div"
                sx={{ height: 140, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <img src={step3Image} alt="Easy Pay" style={{ height: '80px', width: 'auto' }} />
              </CardMedia>
              <CardContent>
                <Typography variant="h6">Easy Pay</Typography>
                <Typography variant="body2" color="textSecondary">
                  Dream Cruiser ensures a smooth payment experience for the users by making all wallet payments secure and quicker. Save money on your tickets.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <footer className="footer">
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Useful Links</Typography>
              <Typography variant="body2">About</Typography>
              <Typography variant="body2">FAQs</Typography>
              <Typography variant="body2">Contact Us</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Policies</Typography>
              <Typography variant="body2">Privacy Policy</Typography>
              <Typography variant="body2">Ticket Policies</Typography>
              <Typography variant="body2">Refund Policy</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Contact Info</Typography>
              <Typography variant="body2">Dream Cruiser, Nairobi</Typography>
              <Typography variant="body2">1234567890</Typography>
              <Typography variant="body2">dreamcruiser.com</Typography>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

export default LandingPage;
