import React, { useState } from 'react';
import axiosInstance from './axiosInstance';
import DataTable from 'react-data-table-component';
import { TextField, Button } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const FinancialReport = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [financialData, setFinancialData] = useState({});
  const offices = [
    "Nairobi", "Thika", "Matuu", "Kathioyoko", "Mwingi", "Nguni", "Ukasi",
    "Bisan Hargeysa", "Bangal", "Madogo", "Garissa", "Shimbirey", "Dujis",
    "Madogashe", "Habaswein", "Qanjara", "Lag Boqol", "Leheley", "Wajir"
  ];

  const fetchFinancialData = async () => {
    try {
      const response = await axiosInstance.get(`/financials/totals/${startDate}/${endDate}`);
      setFinancialData(response.data);
    } catch (error) {
      console.error("Error fetching financial data", error);
    }
  };

  const calculateDeficitSurplus = (parcel, reservation, expenses) => {
    return (parcel + reservation - expenses).toFixed(2);
  };

  const exportToExcel = () => {
    const excelData = offices.map(office => {
      const officeData = financialData[office] || { parcel: 0, reservation: 0, expenses: 0 };
      const deficitSurplus = parseFloat(calculateDeficitSurplus(officeData.parcel, officeData.reservation, officeData.expenses));
      return {
        Office: office,
        Parcel: Number(officeData.parcel).toFixed(2), // Ensure value is a number
        Tickets: Number(officeData.reservation).toFixed(2), // Ensure value is a number
        Expenses: Number(officeData.expenses).toFixed(2), // Ensure value is a number
        'Deficit/Surplus': deficitSurplus // Already a number
      };
    });

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    
    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Financial Report');
    
    // Write the Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
    saveAs(blob, 'financial_report.xlsx');
  };

  const columns = [
    {
      name: 'Metric',
      selector: row => row.metric,
      sortable: true,
    },
    ...offices.map(office => ({
      name: office,
      selector: row => {
        const value = row[office];
        return Number(value).toFixed(2); // Ensure value is a number
      },
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.metric === 'Deficit/Surplus' && row[office] > 0,
          style: {
            backgroundColor: 'green',
            color: 'white',
          },
        },
        {
          when: row => row.metric === 'Deficit/Surplus' && row[office] < 0,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        },
        {
          when: row => row.metric !== 'Deficit/Surplus',
          style: {
            backgroundColor: 'white',
            color: 'black',
          },
        },
      ],
    })),
    {
      name: 'Total',
      selector: row => {
        const value = row.total;
        return (typeof value === 'number' ? value : 0).toFixed(2); // Ensure value is a number
      },
      sortable: true,
    }
  ];

  const metrics = [
    { 
      metric: 'Parcel', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.parcel || 0), 0),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { parcel: 0 };
        acc[office] = Number(officeData.parcel).toFixed(2); // Ensure value is a number
        return acc;
      }, {})
    },
    { 
      metric: 'Tickets', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.reservation || 0), 0),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { reservation: 0 };
        acc[office] = Number(officeData.reservation).toFixed(2); // Ensure value is a number
        return acc;
      }, {})
    },
    { 
      metric: 'Expenses', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.expenses || 0), 0),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { expenses: 0 };
        acc[office] = Number(officeData.expenses).toFixed(2); // Ensure value is a number
        return acc;
      }, {})
    },
    { 
      metric: 'Deficit/Surplus', 
      total: offices.reduce((acc, office) => acc + parseFloat(calculateDeficitSurplus(
        financialData[office]?.parcel || 0, 
        financialData[office]?.reservation || 0, 
        financialData[office]?.expenses || 0
      )), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { parcel: 0, reservation: 0, expenses: 0 };
        acc[office] = parseFloat(calculateDeficitSurplus(officeData.parcel, officeData.reservation, officeData.expenses)).toFixed(2);
        return acc;
      }, {})
    },
  ];

  return (
    <div>
      <h2>Financial Report</h2>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: '10px' }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: '10px' }}
      />
      <Button variant="contained" color="primary" onClick={fetchFinancialData} style={{ marginRight: '10px' }}>
        Get Data
      </Button>
      <Button variant="contained" color="secondary" onClick={exportToExcel}>
        Export to Excel
      </Button>

      <DataTable
        title="Financial Report"
        columns={columns}
        data={metrics}
        pagination
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'blue',
              color: 'white',
            },
          },
          cells: {
            style: {
              backgroundColor: 'white',
              color: 'black',
            },
          },
        }}
      />
    </div>
  );
};

export default FinancialReport;
