import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance';
import { Box, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material';

const QrCodePage = () => {
  const { ticketNumber } = useParams(); // Get the ticket number from the URL
  const [ticketDetails, setTicketDetails] = useState(null);

  useEffect(() => {
    // Fetch ticket details from the API using the ticket number
    const fetchTicketDetails = async () => {
      try {
        const response = await axiosInstance.get(`/reservation/viewticket/${ticketNumber}`);
        setTicketDetails(response.data); // Use response.data to access the data from Axios
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
    };

    fetchTicketDetails();
  }, [ticketNumber]);

  if (!ticketDetails) {
    return <Typography>Loading ticket details...</Typography>;
  }

  return (
    <Box
      sx={{
        marginTop: 4,
        padding: 2,
        border: '1px solid #1976d2',
        borderRadius: '8px',
        backgroundColor: '#e3f2fd',
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2 }}>Ticket Details</Typography>
      <Table size="small" sx={{ margin: 'auto' }}>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Ticket Number</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.ticketNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Passenger Name</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
              {ticketDetails.firstName} {ticketDetails.lastName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>ID Number</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.idNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Phone</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Origin</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.source}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Destination</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.destination}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Book Date</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.bookDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Seat Number</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.seatNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Amount</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.price}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Discount</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
              {ticketDetails.discount ? ticketDetails.discount : '0'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Travel Date</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.travelDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Pickup Location</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>{ticketDetails.pickUpLocation}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>Book Status</TableCell>
            <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
              {ticketDetails.reserved ? 'Reserved' : 'Booked'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default QrCodePage;
