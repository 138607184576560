import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SeatingLayout from '../SeatingLayout';
import AssignBusModal from './AssignBusModal';

const BusDetailsTable = ({ onSelectBus }) => {
  const [busDetails, setBusDetails] = useState({});
  const [showSeatsPanel, setShowSeatsPanel] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const details = JSON.parse(localStorage.getItem('busDetails')) || {};
    setBusDetails(details);
  }, []);

  const source = location.state?.source || 'Unknown';
  const destination = location.state?.destination || 'Unknown';
  const travelDate = location.state?.date || 'Unknown';
  const direction = location.state?.direction || 'Unknown';

  const handleSelectSeat = (seat) => {
    setSelectedSeat(seat);
    setShowSeatsPanel(true);
  };

  const handleSelectBus = () => {
    onSelectBus(busDetails);
    setShowSeatsPanel(true);
  };

  const handleAssignBus = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAssignment = () => {
    handleModalClose();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', height: '100vh' }}>
      <Box sx={{ flexBasis: { xs: '100%', md: '70%' }, padding: 2, overflowY: 'auto' }}>
        <Typography variant="h6">From: {source} To: {destination}</Typography>
        <Typography variant="h6">Date: {travelDate}</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="bus details table">
            <TableHead>
              <TableRow>
                {['Route', 'Departure', 'Report Time', 'Cost', 'Available', 'Reserved', 'Booked', 'Select'].map((header) => (
                  <TableCell key={header} align="center" style={{ backgroundColor: '#383838', color: '#fff', fontWeight: 'bold' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{busDetails.route || '-'}</TableCell>
                <TableCell align="center">{busDetails.departure || '-'}</TableCell>
                <TableCell align="center">{busDetails.reportTime || '-'}</TableCell>
                {/* <TableCell align="center">
                  <Button variant="text" onClick={handleAssignBus}>
                    {busDetails.busRegistration || '-'}
                  </Button>
                </TableCell> */}
                <TableCell align="center">Ksh{busDetails.cost || '-'}</TableCell>
                <TableCell align="center">{busDetails.available || '-'}</TableCell>
                <TableCell align="center">{busDetails.reserved || '-'}</TableCell>
                <TableCell align="center">{busDetails.booked || '-'}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="primary" onClick={() => handleSelectSeat(busDetails.busRegistration)}>Select</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {showSeatsPanel && busDetails && (
        <Box sx={{ flexBasis: { xs: '100%', md: '30%' }, padding: 2, overflowY: 'auto', borderTop: { xs: '2px solid #ccc', md: 'none' }, borderLeft: { md: '2px solid #ccc', xs: 'none' } }}>
          <SeatingLayout
            bus={{ ...busDetails, source, destination, travelDate, direction }}
          />
        </Box>
      )}
      <AssignBusModal
        open={modalOpen}
        onClose={handleModalClose}
        busRegistration={busDetails.busRegistration}
        travelDate={travelDate}
        direction={direction}
        onAssign={handleAssignment}
      />
    </Box>
  );
};

export default BusDetailsTable;
