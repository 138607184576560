// src/components/ContactUs.js

import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container, Grid, Paper } from '@mui/material';
import axiosInstance from './Admin/axiosInstance'; 

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/contact', formData);
      alert('Your message has been sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  return (
    <Box sx={{ bgcolor: 'grey.200', minHeight: '100vh', py: 5 }}> {/* Grey background for the entire screen */}
      <Container>
        <Paper elevation={3} sx={{ p: 4, maxWidth: 450, mx: 'auto', bgcolor: 'white' }}> 
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" color="text.secondary">
              We're here to help! Reach out to us with any inquiries.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center"> {/* Center the grid container */}
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  size="small"
                  label="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  required
                  sx={{ mx: 'auto' }} // Center the input field
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  size="small"
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                  sx={{ mx: 'auto' }} // Center the input field
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  size="small"
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  margin="normal"
                  required
                  sx={{ mx: 'auto' }} // Center the input field
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, px: 4 }}
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactUs;
