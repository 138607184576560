import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axiosInstance from '../Admin/axiosInstance';

const AssignBusModal = ({ open, onClose, busRegistration, travelDate, direction, onAssign }) => {
  const [buses, setBuses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [conductors, setConductors] = useState([]);
  const [selectedBus, setSelectedBus] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [selectedConductor, setSelectedConductor] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const busesResponse = await axiosInstance.get('/bus/viewAll');
        const driversResponse = await axiosInstance.get('/users/drivers');
        const conductorsResponse = await axiosInstance.get('/users/conductors');
  
        console.log('Fetched Buses:', busesResponse.data);
        console.log('Fetched Drivers:', driversResponse.data);
        console.log('Fetched Conductors:', conductorsResponse.data);
  
        setBuses(busesResponse.data);
        setDrivers(driversResponse.data);
        setConductors(conductorsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const handleAssign = async () => {
    try {
      await axiosInstance.post('/schedule/add', {
        busRegistration: selectedBus,
        userName: selectedDriver,
        userName: selectedConductor,
        date: travelDate, 
        direction: direction
      });
      onAssign();
      onClose(); 
    } catch (error) {
      console.error('Error assigning bus:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Bus Details</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', padding: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Bus Registration: { busRegistration}</Typography>

          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Bus</InputLabel>
            <Select
              value={selectedBus}
              onChange={(e) => setSelectedBus(e.target.value)}
              label="Bus"
            >
              {buses.length > 0 ? (
                buses.map((bus, index) => (
                  <MenuItem key={index} value={bus.busRegistration}>
                    {bus.busRegistration}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Buses Available</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Driver</InputLabel>
            <Select
              value={selectedDriver}
              onChange={(e) => setSelectedDriver(e.target.value)}
              label="Driver"
            >
              {drivers.length > 0 ? (
                drivers.map((driverName, index) => (
                  <MenuItem key={index} value={driverName.userName}>
                    {driverName.userName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Drivers Available</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Conductor</InputLabel>
            <Select
              value={selectedConductor}
              onChange={(e) => setSelectedConductor(e.target.value)}
              label="Conductor"
            >
              {conductors.length > 0 ? (
                conductors.map((conductorName, index) => (
                  <MenuItem key={index} value={conductorName.userName}>
                    {conductorName.userName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Conductors Available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAssign}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignBusModal;
