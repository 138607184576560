import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Route, Routes } from 'react-router-dom';
import { Box, Typography, Grid, Paper, Button, Divider, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { Group, MonetizationOn, Event, AccountCircle, DirectionsBus, LocalShipping, CheckCircle, AltRoute } from '@mui/icons-material';
import ManageBuses from './ManageBuses';
// import ManageUsers from './ManageUsers';
import ViewReports from './ViewReports';
import ManageBusSchedules from './ManageBusSchedules';
import ViewTickets from './ViewTickets';
import AddUserForm from './AddUserForm';
import { CSVLink } from 'react-csv';
import axiosInstance from './axiosInstance';

const DashboardPage = () => {
  const [customers, setCustomers] = useState(0);
  const [buses, setBuses] = useState(0);
  const [scheduleId, setScheduleId] = useState('');
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('');

  useEffect(() => {
    // Fetch customers
    axiosInstance.get('/users/viewallusers')
      .then(response => setCustomers(response.data.length))
      .catch(error => console.error('Error fetching customers:', error));

    // Fetch buses
    axiosInstance.get('/bus/viewAll')
      .then(response => setBuses(response.data.length))
      .catch(error => console.error('Error fetching buses:', error));
    
    // Set current date
    const currentDate = new Date().toLocaleDateString();
    setDate(currentDate);
  }, []);

  const handleFetchBookings = () => {
    setLoading(true);
    axiosInstance.get(`/reservation/manifest/${scheduleId}`)
      .then(response => {
        setBookingData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching bookings:', error);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Welcome 
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ef5350', height: 120  }}>
            <Group sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Users</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>{customers}</Typography>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ffa726' }}>
            <MonetizationOn sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Bookings</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>6</Typography>
            </Box>
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#66bb6a', height: 120 }}>
            <Event sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Date</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>{date}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#29b6f6', height: 120  }}>
            <AccountCircle sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Account</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>admin</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#66bb6a', height: 120  }}>
            <DirectionsBus sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Buses</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>{buses}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#42a5f5', height: 120  }}>
            <LocalShipping sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Parcels</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}></Typography>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ef5350' }}>
            <CheckCircle sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Availability</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>3</Typography>
            </Box>
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ffa726', height: 120  }}>
            <AltRoute sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Routes</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>2</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 4 }}>
        <Routes>
          <Route path="dashboard" element={<Typography>Welcome to the Admin Dashboard</Typography>} />
          <Route path="manage-buses" element={<ManageBuses />} />
          <Route path="add-user" element={<AddUserForm />} />
          <Route path="view-reports" element={<ViewReports />} />
          <Route path="manage-schedule" element={<ManageBusSchedules />} />
          <Route path="view-tickets" element={<ViewTickets />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default DashboardPage;
