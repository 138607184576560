import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Modal, IconButton, TablePagination } from '@mui/material';
import axiosInstance from './axiosInstance';
import CloseIcon from '@mui/icons-material/Close';

const ViewTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [busRegistration, setBusRegistration] = useState('');
  const [date, setDate] = useState('');
  const [userName, setUserName] = useState('');
  const [ticketNumber, setTicketNumber] = useState('');
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [searchType, setSearchType] = useState('bus');
  const [editTicket, setEditTicket] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editForm, setEditForm] = useState({
    bookDate: '',
    bookTime: '',
    firstName: '',
    lastName: '',
    idNumber: '',
    modeOfPayment: '',
    phone: '',
    price: '',
    seatNumber: '',
    source: '',
    destination: '',
    ticketNumber: '',
    userName: '',
    busRegistration: ''
  });

  useEffect(() => {
    fetchTickets();
  }, []);

  useEffect(() => {
    if (searchType === 'bus' && busRegistration && date) {
      fetchFilteredTickets();
    } else if (searchType === 'user' && userName && date) {
      fetchFilteredUserTickets();
    } else if (searchType === 'ticket' && ticketNumber) {
      fetchFilteredTicketByNumber();
    } else {
      setFilteredTickets(tickets);
    }
  }, [busRegistration, date, userName, ticketNumber, tickets, searchType]);

  const fetchTickets = async () => {
    try {
      const response = await axiosInstance.get('/reservation/viewall');
      const data = response.data;
      setTickets(Array.isArray(data) ? data : []);
      setFilteredTickets(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const fetchFilteredTickets = async () => {
    try {
      const response = await axiosInstance.get(`/reservation/dateandbus/${busRegistration}/${date}`);
      const data = response.data;
      setFilteredTickets(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching filtered tickets:', error);
    }
  };

  const fetchFilteredUserTickets = async () => {
    try {
      const response = await axiosInstance.get(`/reservation/userreservation/${userName}/${date}`);
      const data = response.data;
      setFilteredTickets(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching filtered user tickets:', error);
    }
  };

  const fetchFilteredTicketByNumber = async () => {
    try {
      const response = await axiosInstance.get(`/reservation/viewticket/${ticketNumber}`);
      const data = response.data;
      setFilteredTickets(data ? [data] : []);
    } catch (error) {
      console.error('Error fetching filtered ticket by number:', error);
    }
  };

  const handleSearch = () => {
    if (searchType === 'bus') {
      fetchFilteredTickets();
    } else if (searchType === 'user') {
      fetchFilteredUserTickets();
    } else if (searchType === 'ticket') {
      fetchFilteredTicketByNumber();
    }
  };

  const handleEditClick = (ticket) => {
    setEditTicket(ticket);
    setEditForm(ticket);
  };

  const handleCloseModal = () => {
    setEditTicket(null);
    setEditForm({
      bookDate: '',
      bookTime: '',
      firstName: '',
      lastName: '',
      idNumber: '',
      modeOfPayment: '',
      phone: '',
      price: '',
      seatNumber: '',
      source: '',
      destination: '',
      ticketNumber: '',
      userName: '',
      busRegistration: ''
    });
  };

  const handleFormChange = (e) => {
    setEditForm({
      ...editForm,
      [e.target.name]: e.target.value
    });
  };

  const handleUpdateTicket = async () => {
    try {
      if (!editTicket) return;
      await axiosInstance.put(`/reservation/update/${editTicket.reservationId}`, editForm);
      fetchTickets();
      handleCloseModal();
    } catch (error) {
      console.error('Error updating ticket:', error);
    }
  };

  const handleDelete = async (reservationId) => {
    try {
      await axiosInstance.delete(`/reservation/delete/${reservationId}`);
      fetchTickets();
    } catch (error) {
      console.error('Error deleting ticket:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        View Booked Tickets
      </Typography>
      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Button
            variant={searchType === 'bus' ? 'contained' : 'outlined'}
            onClick={() => setSearchType('bus')}
            sx={{ marginRight: 2 }}
          >
            Search by Bus
          </Button>
          <Button
            variant={searchType === 'user' ? 'contained' : 'outlined'}
            onClick={() => setSearchType('user')}
            sx={{ marginRight: 2 }}
          >
            Search by User
          </Button>
          <Button
            variant={searchType === 'ticket' ? 'contained' : 'outlined'}
            onClick={() => setSearchType('ticket')}
            sx={{ marginRight: 2 }}
          >
            Search by Ticket Number
          </Button>
        </Box>
        {searchType === 'bus' ? (
          <>
            <TextField
              label="Bus Registration"
              size='small'
              value={busRegistration}
              onChange={(e) => setBusRegistration(e.target.value)}
              sx={{ marginRight: 2 }}
            />
            <TextField
              type="date"
              size='small'
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{ marginRight: 2 }}
            />
          </>
        ) : searchType === 'user' ? (
          <>
            <TextField
              label="User Name"
              size='small'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              sx={{ marginRight: 2 }}
            />
            <TextField
              type="date"
              size='small'
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{ marginRight: 2 }}
            />
          </>
        ) : (
          <>
            <TextField
              label="Ticket Number"
              size='small'
              value={ticketNumber}
              onChange={(e) => setTicketNumber(e.target.value)}
              sx={{ marginRight: 2 }}
            />
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      </Box>
      <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: '#1976d2' }}>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}>Book Date</TableCell>
            <TableCell sx={{ color: '#fff' }}>Book Time</TableCell>
            <TableCell sx={{ color: '#fff' }}>First Name</TableCell>
            <TableCell sx={{ color: '#fff' }}>Last Name</TableCell>
            <TableCell sx={{ color: '#fff' }}>ID Number</TableCell>
            <TableCell sx={{ color: '#fff' }}>Mode of Payment</TableCell>
            <TableCell sx={{ color: '#fff' }}>Phone</TableCell>
            <TableCell sx={{ color: '#fff' }}>Price</TableCell>
            <TableCell sx={{ color: '#fff' }}>Seat Number</TableCell>
            <TableCell sx={{ color: '#fff' }}>Destination</TableCell>
            <TableCell sx={{ color: '#fff' }}>Source</TableCell>
            <TableCell sx={{ color: '#fff' }}>Ticket Number</TableCell>
            <TableCell sx={{ color: '#fff' }}>Bus Registration</TableCell>
            <TableCell sx={{ color: '#fff' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ticket) => (
            <TableRow key={ticket.reservationId}>
              <TableCell>{ticket.bookDate}</TableCell>
              <TableCell>{ticket.bookTime}</TableCell>
              <TableCell>{ticket.firstName}</TableCell>
              <TableCell>{ticket.lastName}</TableCell>
              <TableCell>{ticket.idNumber}</TableCell>
              <TableCell>{ticket.modeOfPayment}</TableCell>
              <TableCell>{ticket.phone}</TableCell>
              <TableCell>{ticket.price}</TableCell>
              <TableCell>{ticket.seatNumber}</TableCell>
              <TableCell>{ticket.destination}</TableCell>
              <TableCell>{ticket.source}</TableCell>
              <TableCell>{ticket.ticketNumber}</TableCell>
              <TableCell>{ticket.busRegistration}</TableCell>
              <TableCell>
                <Button onClick={() => handleEditClick(ticket)}>Edit</Button>
                <Button onClick={() => handleDelete(ticket.reservationId)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={filteredTickets.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
      <Modal
        open={Boolean(editTicket)}
        onClose={handleCloseModal}
        aria-labelledby="edit-ticket-modal"
        aria-describedby="edit-ticket-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',maxHeight: '80vh', overflow: 'auto', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography id="edit-ticket-modal-title" variant="h6" component="h2">
            Edit Ticket
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            label="Book Date"
            name="bookDate"
            type="date"
            value={editForm.bookDate}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Book Time"
            name="bookTime"
            value={editForm.bookTime}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="First Name"
            name="firstName"
            value={editForm.firstName}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={editForm.lastName}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="ID Number"
            name="idNumber"
            value={editForm.idNumber}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Mode of Payment"
            name="modeOfPayment"
            value={editForm.modeOfPayment}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Phone"
            name="phone"
            value={editForm.phone}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Price"
            name="price"
            value={editForm.price}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Seat Number"
            name="seatNumber"
            value={editForm.seatNumber}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Source"
            name="source"
            value={editForm.source}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Destination"
            name="destination"
            value={editForm.destination}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Ticket Number"
            name="ticketNumber"
            value={editForm.ticketNumber}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="User Name"
            name="userName"
            value={editForm.userName}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Bus Registration"
            name="busRegistration"
            value={editForm.busRegistration}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <Button onClick={handleUpdateTicket} variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewTickets;
