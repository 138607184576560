import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance'; 
import {
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    IconButton,
    FormGroup, 
    FormControlLabel, 
    Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const busRoutes = [
    {
        id: 1,
        name: 'Nairobi to Wajir',
        direction: 1, // outbound
        route: [
            'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
            'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis',
            'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
        ]
    },
    {
        id: 2,
        name: 'Wajir to Nairobi',
        direction: 2, // inbound
        route: [
            'Wajir', 'Laheley', 'Lag boqol', 'Qanchara', 'Habaswein', 'Madogashe', 'Dujis',
            'Shimbirey', 'Garissa', 'Madogo', 'Tula', 'Bangal', 'Katumba', 'Bisan hargeysa',
            'Ukasi', 'Mwingi', 'Kathiyoko', 'Matuu', 'Thika', 'Nairobi'
        ]
    }
];

const ParcelBooking = ({ onClose }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        senderName: '',
        senderPhone: '',
        receiverName: '',
        receiverPhone: '',
        recipientAddress: '',
        parcelContent: '',
        packageType: '',
        price: '',
        source: '',
        destination: '',
        modeOfPayment: '',
        parcelWeight: '', 
        itemsCount: '', // New field
        status: 'INPROGRESS',
        direction: '',
        mpesaNumber: '', // Mpesa specific field
        airtelNumber: '', // Airtel specific field
        mpesaAmount: '', // Amount for Mpesa payment
        cashAmount: '',
        fragile: false,
        highValue: false,
        perishable: false,
        urgent: false,
        valueInKsh: '',
        serialNumber: ''
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    

    const getDirection = (source, destination) => {
        const locations = [
            'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
            'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
            'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
        ];
        
        const sourceIndex = locations.indexOf(source);
        const destinationIndex = locations.indexOf(destination);
    
        if (sourceIndex < destinationIndex) {
            return `${source} - ${destination}`;
        } else {
            return `${destination} - ${source}`;
        }
    };

    const handleChangeB = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: fieldValue
        }));

         // If FOC is selected, automatically set price to 0
        if (name === 'modeOfPayment' && value === 'foc') {
            setFormData((prevState) => ({
                ...prevState,
                price: 0, // Set price to 0 for FOC
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };
    
        // Determine the direction based on the source and destination
        if (name === 'source' || name === 'destination') {
            const { source, destination } = updatedFormData;
    
            let direction = '';
    
            if (source && destination) {
                // Check the outbound route (Nairobi -> Wajir)
                if (busRoutes[0].route.includes(source) && busRoutes[0].route.includes(destination)) {
                    const sourceIndex = busRoutes[0].route.indexOf(source);
                    const destinationIndex = busRoutes[0].route.indexOf(destination);
    
                    if (sourceIndex < destinationIndex) {
                        direction = busRoutes[0].direction; // Set direction to 1 for outbound
                    }
                }
    
                // Check the inbound route (Wajir -> Nairobi)
                if (!direction && busRoutes[1].route.includes(source) && busRoutes[1].route.includes(destination)) {
                    const sourceIndex = busRoutes[1].route.indexOf(source);
                    const destinationIndex = busRoutes[1].route.indexOf(destination);
    
                    if (sourceIndex < destinationIndex) {
                        direction = busRoutes[1].direction; // Set direction to 2 for inbound
                    }
                }
            }
    
            updatedFormData = { ...updatedFormData, direction };
        }
        if (name === 'modeOfPayment' && value === 'FOC') {
            setFormData(prevState => ({
                ...prevState,
                price: 0 // Set price to zero for FOC
            }));
        }
    
        setFormData(updatedFormData);
    };


    const handlePaymentMethodChange = (e) => {
        const selectedMethod = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            modeOfPayment: selectedMethod,
            price: selectedMethod === 'foc' ? 0 : prevState.price // Set price to 0 for FOC
        }));
    };
    
    const validateForm = () => {
        const newErrors = {};
        const phoneRegex = /^(1|7)\d{8}$/;

        if (!formData.senderName) {
            newErrors.senderName = 'Sender name is required';
        }
    
        if (!formData.senderPhone) {
            newErrors.senderPhone = 'Sender phone is required';
        } else if (!phoneRegex.test(formData.senderPhone)) {
            newErrors.senderPhone = 'Sender phone must start with 1 or 7 and be followed by 9 digits';
        }
    
        if (!formData.receiverName) {
            newErrors.receiverName = 'Recipient name is required';
        }
    
        if (!formData.receiverPhone) {
            newErrors.receiverPhone = 'Recipient phone is required';
        } else if (!phoneRegex.test(formData.receiverPhone)) {
            newErrors.receiverPhone = 'Recipient phone must start with 1 or 7 and be followed by 8 digits';
        }
        if (!formData.recipientAddress) newErrors.recipientAddress = 'Recipient address is required';
        if (!formData.parcelContent) newErrors.parcelContent = 'Parcel content is required';
        if (formData.modeOfPayment !== 'foc' && !formData.price) {
            newErrors.price = 'Price is required';
        }
        if (!formData.source) newErrors.source = 'Origin is required';
        if (!formData.destination) newErrors.destination = 'Destination is required';
        if (!formData.modeOfPayment) newErrors.modeOfPayment = 'Mode of payment is required';
        if (!formData.parcelWeight) newErrors.parcelWeight = 'Parcel weight is required'; // Existing validation
        if (!formData.itemsCount) newErrors.itemsCount = 'Items count is required'; // New validation
        return newErrors;
    };

    const generateTrackingCode = () => {
        const characters = '0123456789'; // Numeric digits only
        let trackingCode = ''; // Initialize with 'DCL'
        for (let i = 0; i < 12; i++) {
            trackingCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return trackingCode;
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      };
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        
        const trackingCode = generateTrackingCode();
        // const dataToSend = { ...formData, trackingCode };

        // Ensure price is sent as 0 for FOC
        const dataToSend = {
            ...formData,
            // senderPhone: `+254${formData.senderPhone}`,
            // receiverPhone: `+254${formData.receiverPhone}`,
            trackingCode,
            price: formData.modeOfPayment === 'foc' ? 0 : formData.price, // Set price to 0 if FOC
        };
        
        try {
            await axiosInstance.post('/parcel/add', dataToSend);
            setSuccessMessage(`Parcel registered successfully! Tracking Number: ${trackingCode}`);
            setErrorMessage('');
            onClose();
            navigate('/receipt', { state: { trackingCode, ...formData } });
            setFormData({
                senderName: '',
                senderPhone: '',
                receiverName: '',
                receiverPhone: '',
                recipientAddress: '',
                parcelContent: '',
                packageType: '',
                price: '',
                source: '',
                destination: '',
                modeOfPayment: '',
                parcelWeight: '',
                itemsCount: '',
                status: 'INPROGRESS',
                direction: ''  
            });
        } catch (error) {
            setErrorMessage('There was an error registering the parcel.');
            setSuccessMessage('');
        }
    };

    const locations = [
        'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
        'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
        'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
    ];

    const paymentOptions = [
        { id: 'Cash', label: 'Cash' },
        { id: 'Mpesa', label: 'Mpesa' },
        { id: 'mcash', label: 'Mpesa and Cash' }, 
        { id: 'foc', label: 'FOC' }, 
        { id: 'AirtelMoney', label: 'Airtel Money' }
    ];
    


    return (
        <>
            <Container maxWidth="sm">
                <Box sx={{ mt: 4 }}>
                    <IconButton onClick={onClose} sx={{ float: 'right' }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Parcel Acceptance
                    </Typography>
                    {successMessage && <Typography color="primary">{successMessage}</Typography>}
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Existing form fields */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Sender Name"
                                    name="senderName"
                                    value={formData.senderName}
                                    onChange={handleChange}
                                    error={!!errors.senderName}
                                    helperText={errors.senderName}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                               fullWidth
                               label="Sender Phone"
                               name="senderPhone"
                               value={formData.senderPhone ? formData.senderPhone.replace('+254', '') : ''}
                               onChange={(e) => handleChange(e, 'senderPhone')}
                               error={!!errors.senderPhone}
                               helperText={errors.senderPhone}
                               required
                               placeholder="Enter 9 digits start with 7 or 1"
                            />
                                                    </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Recipient Name"
                                    name="receiverName"
                                    value={formData.receiverName}
                                    onChange={handleChange}
                                    error={!!errors.receiverName}
                                    helperText={errors.receiverName}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                               fullWidth
                               label="Recipient Phone"
                               name="receiverPhone"
                               value={formData.receiverPhone ? formData.receiverPhone.replace('+254', '') : ''}
                               onChange={(e) => handleChange(e, 'receiverPhone')}
                               error={!!errors.receiverPhone}
                               helperText={errors.receiverPhone}
                               required
                               placeholder="Enter 9 digits start with 7 or 1"
                            />
                            </Grid>
                            <Grid item xs={12} sm ={6}>
                                <TextField
                                    fullWidth
                                    label="Recipient Address"
                                    name="recipientAddress"
                                    value={formData.recipientAddress}
                                    onChange={handleChange}
                                    error={!!errors.recipientAddress}
                                    helperText={errors.recipientAddress}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Items Count"
                                    name="itemsCount"
                                    type="number"
                                    value={formData.itemsCount}
                                    onChange={handleChange}
                                    error={!!errors.itemsCount}
                                    helperText={errors.itemsCount}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Parcel Content"
                                    name="parcelContent"
                                    value={formData.parcelContent}
                                    onChange={handleChange}
                                    error={!!errors.parcelContent}
                                    helperText={errors.parcelContent}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Packaging"
                                    name="packageType"
                                    value={formData.packageType}
                                    onChange={handleChange}
                                    error={!!errors.packageType}
                                    helperText={errors.packageType}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required error={!!errors.source}>
                                    <InputLabel id="source-label">Origin</InputLabel>
                                    <Select
                                        labelId="source-label"
                                        name="source"
                                        value={formData.source}
                                        onChange={handleChange}
                                        label="Origin"
                                    >
                                        {locations.map((location, index) => (
                                            <MenuItem key={index} value={location}>
                                                {location}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.source && <FormHelperText>{errors.source}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required error={!!errors.destination}>
                                    <InputLabel id="destination-label">Destination</InputLabel>
                                    <Select
                                        labelId="destination-label"
                                        name="destination"
                                        value={formData.destination}
                                        onChange={handleChange}
                                        label="Destination"
                                    >
                                        {locations.map((location, index) => (
                                            <MenuItem key={index} value={location}>
                                                {location}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.destination && <FormHelperText>{errors.destination}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            {/* New checkboxes for FRAGILE, HIGH VALUE, PERISHABLE, URGENT */}
                            <Grid item xs={12}>
                                <FormGroup row> {/* Add the "row" prop to make the checkboxes horizontal */}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.fragile}
                                                onChange={handleChangeB}
                                                name="fragile"
                                            />
                                        }
                                        label="FRAGILE"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.highValue}
                                                onChange={handleChangeB}
                                                name="highValue"
                                            />
                                        }
                                        label="HIGH VALUE"
                                    />
                                    {formData.highValue && (
                                        <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Value in Ksh"
                                                name="valueInKsh"
                                                value={formData.valueInKsh}
                                                onChange={handleChangeB}
                                                error={!!errors.valueInKsh}
                                                helperText={errors.valueInKsh}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Product serial"
                                                name="serialNumber"
                                                value={formData.serialNumber}
                                                onChange={handleChangeB}
                                                error={!!errors.serialNumber}
                                                helperText={errors.serialNumber}
                                                required
                                            />
                                        </Grid>
                                        </>
                                    )}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.perishable}
                                                onChange={handleChangeB}
                                                name="perishable"
                                            />
                                        }
                                        label="PERISHABLE"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.urgent}
                                                onChange={handleChangeB}
                                                name="urgent"
                                            />
                                        }
                                        label="URGENT"
                                    />
                                </FormGroup>
                            </Grid>

                            {/* Payment Mode Selection */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required error={!!errors.modeOfPayment}>
                                    <InputLabel id="payment-label">Mode of Payment</InputLabel>
                                    <Select
                                        labelId="payment-label"
                                        name="modeOfPayment"
                                        value={formData.modeOfPayment}
                                        onChange={handleChange}
                                        label="Mode of Payment"
                                    >
                                    {paymentOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.label} {/* Display the label */}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.modeOfPayment && <FormHelperText>{errors.modeOfPayment}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            {/* Mpesa and Airtel Money Input Fields */}
                            {(formData.modeOfPayment === 'Mpesa' || formData.modeOfPayment === 'AirtelMoney') && (
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={`Enter ${formData.modeOfPayment} Number`}
                                        name={formData.modeOfPayment === 'Mpesa' ? 'mpesaNumber' : 'airtelNumber'}
                                        value={formData.modeOfPayment === 'Mpesa' ? formData.mpesaNumber : formData.airtelNumber}
                                        onChange={handleChange}
                                        error={!!errors[formData.modeOfPayment === 'Mpesa' ? 'mpesaNumber' : 'airtelNumber']}
                                        helperText={errors[formData.modeOfPayment === 'Mpesa' ? 'mpesaNumber' : 'airtelNumber']}
                                        fullWidth
                                    />
                                </Grid>
                            )}

                            {/* Mixed Payment: Mpesa and Cash */}
                            {formData.modeOfPayment === 'mcash' && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Amount to Pay via Mpesa"
                                            name="mpesaAmount"
                                            type="number"
                                            value={formData.mpesaAmount}
                                            onChange={handleChange}
                                            error={!!errors.mpesaAmount}
                                            helperText={errors.mpesaAmount}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Mpesa Number"
                                            name="mpesaNumber"
                                            type="number"
                                            value={formData.mpesaNumber}
                                            onChange={handleChange}
                                            error={!!errors.mpesaNumber}
                                            helperText={errors.mpesaNumber}
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Price"
                                    name="price"
                                    type="number"
                                    value={formData.price}
                                    onChange={handleChange}
                                    InputProps={{
                                        readOnly: formData.modeOfPayment === 'foc',
                                    }}
                                    fullWidth
                                    required
                                    disabled={formData.modeOfPayment === 'foc'}
                                    style={{
                                        backgroundColor: formData.modeOfPayment === 'foc' ? '#e0e0e0' : 'transparent', // Grey background when disabled
                                    }}
                                />
                            </Grid>


                                    
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Parcel Weight(Kg)"
                                    name="parcelWeight"
                                    type="number"
                                    value={formData.parcelWeight}
                                    onChange={handleChange}
                                    error={!!errors.parcelWeight}
                                    helperText={errors.parcelWeight}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
                                Register Parcel
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </>
    );
};

export default ParcelBooking;
