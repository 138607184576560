import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  IconButton,
  Typography,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination
} from '@mui/material';
import { Delete, AddCircle, Edit } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageBuses = () => {
  const [buses, setBuses] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [form, setForm] = useState({
    busRegistration: '',
    make: '',
    type: '',
    ownership: ''
  });
  const [editBus, setEditBus] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Dropdown options
  const makes = ['Mercedes', 'Volvo', 'Scania', 'Isuzu', 'BMW', 'Audi', 'Toyota', 'Honda', 'Ford', 'Chevrolet', 'Nissan', 'Kia', 'Hyundai', 'Tesla', 'Jeep', 'Lexus', 'Land Rover', 'Porsche', 'Ferrari', 'Lamborghini', 'Mazda', 'Suzuki'];
  const types = ['Bus', 'Lorry', 'Motorcycle', 'Pickup', 'Van', 'Saloon', 'Tuktuk', 'Semi-Trailer'];
  const ownerships = ['Hired', 'Leased', 'Owned'];

  useEffect(() => {
    fetchBuses();
  }, []);

  const fetchBuses = async () => {
    try {
      const response = await axiosInstance.get('/bus/viewAll');
      setBuses(response.data);
    } catch (error) {
      console.error('Error fetching buses:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddBus = async () => {
    try {
      if (editBus) {
        await axiosInstance.put(`/bus/update/${editBus.busRegistration}`, form);
        setSuccessMessage('Bus updated successfully!');
      } else {
        await axiosInstance.post('/bus/add', form);
        setSuccessMessage('Bus added successfully!');
      }
      fetchBuses();
      handleCancelClick();
    } catch (error) {
      console.error('Error adding/updating bus:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleEditBus = (bus) => {
    setEditBus(bus);
    setForm({
      busRegistration: bus.busRegistration,
      make: bus.make,
      type: bus.type,
      ownership: bus.ownership
    });
    setShowForm(true);
  };

  const handleDeleteBus = async (busRegistration) => {
    try {
      await axiosInstance.delete(`/bus/delete/${busRegistration}`);
      fetchBuses();
    } catch (error) {
      console.error('Error deleting bus:', error);
    }
  };

  const handleCancelClick = () => {
    setShowForm(false);
    setForm({
      busRegistration: '',
      make: '',
      type: '',
      ownership: ''
    });
    setEditBus(null);
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      {showForm ? (
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400 }}>
          <Typography variant="h6">{editBus ? 'Edit Bus' : 'Add New Bus'}</Typography>
          <TextField
            label="Bus Registration Number"
            name="busRegistration"
            value={form.busRegistration}
            onChange={handleInputChange}
            required
          />
          <FormControl fullWidth>
            <InputLabel>Make</InputLabel>
            <Select
              name="make"
              value={form.make}
              onChange={handleInputChange}
              required
            >
              {makes.map((make) => (
                <MenuItem key={make} value={make}>
                  {make}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={form.type}
              onChange={handleInputChange}
              required
            >
              {types.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Ownership</InputLabel>
            <Select
              name="ownership"
              value={form.ownership}
              onChange={handleInputChange}
              required
            >
              {ownerships.map((ownership) => (
                <MenuItem key={ownership} value={ownership}>
                  {ownership}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleAddBus}>
            {editBus ? 'Update Bus' : 'Add Bus'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Button 
              variant="contained"
              color="primary"
              startIcon={<AddCircle />} 
              onClick={() => setShowForm(true)}
            >
              Add Bus
            </Button>
          </Box>
          <Typography variant="h6" gutterBottom>Bus List</Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#1976d2' }}>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Bus Registration Number</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Make</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Type</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Ownership</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buses
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((bus) => (
                    <TableRow key={bus.busRegistration}>
                      <TableCell>{bus.busRegistration}</TableCell>
                      <TableCell>{bus.make}</TableCell>
                      <TableCell>{bus.type}</TableCell>
                      <TableCell>{bus.ownership}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditBus(bus)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteBus(bus.busRegistration)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={buses.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        </Box>
      )}

      <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageBuses;
